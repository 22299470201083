<template>
  <ThredFrame kind="system">
    <div class="systemDetail_title">
      <h2>
        <span
          v-for="item of itemData.category"
          :key="item"
          class="tag_system"
          :class="sysCategoryClass(item)"
          >{{ item }}</span
        >
        <strong>{{ itemData.title }}</strong>
      </h2>
      <h3>{{ itemData.department_name }}</h3>
    </div>
    <div class="systemDetail_contents">
      <div class="metaData">
        <p class="date">{{ fmtymdhmS(itemData.publish_start_date) }}</p>
      </div>
      <div class="main_contents">
        <div class="txt_contents" v-html="$sanitize(body)"></div>
      </div>
    </div>
  </ThredFrame>
</template>
<script>
import view from '../mixin/view';
import api from '../services/api';
import dataset from '../mixin/dataset';
import thread from '../mixin/thread';
import ThredFrame from '../components/ThredFrame.vue';
export default {
  name: 'SystemInfo',
  components: { ThredFrame },
  mixins: [view, dataset, thread],
  // data() {
  // 	return {};
  // },
  computed: {
    itemData() {
      return this.items.length > 0 ? this.items[0] : {};
    },
    body() {
      if (!this.itemData.body) return '';
      return this.unescapeHtml(this.itemData.body).replaceAll('\\"', '"');
    },
  },

  methods: {
    setItems() {
      let id = this.$route.query.id;
      this.setList({ contentsId: id });
    },
    async getData(params) {
      return await api.getSystemDetail(params);
    },
  },
};
</script>
