<template>
  <SearchFrame title="お知らせ投稿" kind="newsPost">
    <SearchFrameInformationPost
      title="お知らせ投稿"
      ref="search"
      :action="setList"
    />
  </SearchFrame>
  <nav class="addition newsPost">
    <button
      class="btn type_addition modalOpen"
      data-modal="#modal-addition_news"
      @click.prevent="$refs.dlg.resetData(-1)"
    >
      投稿
    </button>
  </nav>
  <ListFrame
    ref="list"
    :totalCount="totalCount"
    :action="setList"
    class="list_result newsPost"
  >
    <div class="result_contents">
      <nav class="sort">
        <div class="sort_item status" data-sort="status">
          <h3>ステータス</h3>
        </div>
        <div class="sort_item category" data-sort="category">
          <h3>カテゴリー</h3>
        </div>
        <div class="sort_item importance" data-sort="importance">
          <h3>重要度</h3>
        </div>
        <div class="sort_item date" data-sort="publish_start_date">
          <h3>掲載開始</h3>
        </div>
        <div class="sort_item date" data-sort="publish_end_date">
          <h3>掲載終了</h3>
        </div>
        <!--スマホの表示箇所調整用のdivです。中身はないけど消さないで欲しいです。-->
        <div class="sp_space"></div>
        <div class="sort_item title" data-sort="title">
          <h3>タイトル</h3>
        </div>
        <div class="sort_item group" data-sort="department_name">
          <h3>部門</h3>
        </div>
        <!--スマホの表示箇所調整用のdivです。中身はないけど消さないで欲しいです。-->
        <div class="sp_space"></div>
      </nav>

      <div
        class="result_contents_item"
        v-for="item of items"
        :key="item.information_id"
      >
        <a
          href="#"
          class="modalOpen"
          data-modal="#modal-addition_news"
          @click.prevent="$refs.dlg.resetData(item.information_id)"
        >
          <div class="status">
            <p>
              <span class="tag_PostStatus" :class="statusClass(item.status)">{{
                item.status
              }}</span>
            </p>
          </div>
          <div class="category">
            <p>
              <span class="news_category">{{ item.category }}</span>
            </p>
          </div>
          <div class="importance">
            <p>
              <span
                class="asideIcon important"
                :class="importanceFlag(item.importance_id) ? 'high' : ''"
              ></span>
            </p>
          </div>
          <div class="date">
            <p>{{ fmtymdS(item.publish_start_date) }}</p>
          </div>
          <div class="date">
            <p>{{ fmtymdS(item.publish_end_date) }}</p>
          </div>
          <div class="title">
            <h3>{{ item.title }}</h3>
            <p class="attachedFile">
              <span v-show="item.file_flag" class="icon attached" />
            </p>
          </div>
          <div class="group">
            <p>{{ item.department_name }}</p>
          </div>
        </a>
      </div>
    </div>
  </ListFrame>

  <InfoPostDialog :action="setList" ref="dlg" />
</template>
<script>
import view from '../mixin/view';
import dataset from '../mixin/dataset';
import SearchFrame from '../components/SearchFrame.vue';
import SearchFrameInformationPost from '../components/SearchFrameInformationPost.vue';
import ListFrame from '../components/ListFrame.vue';
import InfoPostDialog from '../components/InfoPostDialog.vue';
import api from '../services/api';

export default {
  name: 'InformationPost',
  components: {
    SearchFrame,
    SearchFrameInformationPost,
    ListFrame,
    InfoPostDialog,
  },
  mixins: [view, dataset],
  methods: {
    async getData(params) {
      if (!params.orders) params.orders = '-publish_start_date';
      return await api.getInformationList(params);
    },
  },
  watch: {
    items() {
      setTimeout(this.bindModalButton, 100);
    },
  },
  data() {
    return { id: -1 };
  },
  mounted() {
    this.conditionSearch();
  },
};
</script>
