<template>
  <div class="loadData">
    <div class="loader"><span></span></div>
    <div class="top_watchList">
      <div
        v-for="item of items"
        :key="item.contents_id"
        class="top_watchList_item"
      >
        <a :href="linkUrl(item)" @click.prevent="getLink(item)">
          <h3 class="container">
            <span
              :class="importanceFlag(item.importance_id) ? 'article' : ''"
              >{{ type(item) }}</span
            >
            <strong>{{ item.title || item.body }}</strong>
          </h3>
          <dl>
            <dt>{{ fmtymdS(item.display_date) }}</dt>
            <dd>{{ department_name(item) }}</dd>
            <br v-show="!department_name(item)" />
          </dl>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../services/api';
export default {
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.setList();
  },
  computed: {
    linkUrl() {
      let get = (id, type) => {
        switch (type) {
          case this.contentsType.information:
            return `/detail_news?id=${id}`;
          case this.contentsType.chat:
            return `/detail_chat?id=${id}`;
        }
      };
      return function (item) {
        return get(item.contents_id, item.contents_type);
      };
    },
    type() {
      let get = (type) => {
        switch (type) {
          case this.contentsType.information:
            return `お知らせ`;
          case this.contentsType.chat:
            return `チャット`;
        }
      };
      return function (item) {
        return get(item.contents_type);
      };
    },
    department_name() {
      return function (item) {
        return (
          item.reply_organization_name ||
          item.chat_organization_name ||
          item.department_name
        );
      };
    },
  },
  methods: {
    async setList() {
      let params = {
        pageLimit: 6,
        pageNumber: 1,
      };
      let types = Object.values(this.contentsType);
      let datas = await Promise.all(
        types.map(async (type) => {
          params.contentsType = type;
          let ret = await this.getData(params);
          let items = ret.contents || [];
          items.map((x) => (x.contents_type = type));
          return items;
        })
      );
      //最新のデータを6件取り出す
      this.items = datas
        .flat()
        .sort((a, b) => {
          if (b.update_datetime == a.update_datetime) return 0;
          return b.update_datetime > a.update_datetime ? 1 : -1;
        })
        .slice(0, 6);
    },
    async getData(params) {
      return await api.getWatchList(params);
    },
    getLink(item) {
      this.gaEvent('top_click', {
        category: 'ウォッチ',
        title: item.title || item.contents_id,
      });
      let link = this.linkUrl(item);
      this.$router.push(link);
    },
  },
};
</script>
