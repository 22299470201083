export const splt = '｜';
export default {
  data() {
    return {
      API_TIMEOUT: 700,
      pageLimitItems: [
        {
          key: 10,
        },
        {
          key: 20,
        },
        {
          key: 50,
        },
        {
          key: 100,
        },
      ],

      // infoCateCommon: 1,
      // infoCateSales: 2,
      // infoCateNam: 3,
      infoCateNote: 4,

      infoCategoryItems: [],
      chatCategoryItems: [],
      organizationItems: [],
      displayTargetItems: [
        {
          id: 1,
          label: 'NR営業',
        },
        {
          id: 2,
          label: 'NR営業+NLC',
        },
        {
          id: 3,
          label: 'NR秘密情報参照(賃金改定等)',
        },
        {
          id: 4,
          label: 'NR秘密情報参照(NR就業規則)',
          hideItem: true,
        },
        {
          id: 5,
          label: 'NRG秘密情報参照(人事回章等)',
        },
        {
          id: 9,
          label: '一般コンテンツ',
        },
      ],
      goodCountRangeItems: [
        {
          label: '選択してください',
          value: '',
        },
        {
          label: '1～100件',
          value: `1${splt}100`,
        },
        {
          label: '101～1000件',
          value: `101${splt}1000`,
        },
        {
          label: '1001件～',
          value: `1001${splt}99999`,
        },
      ],
      replyCountRangeItems: [
        {
          label: '選択してください',
          value: '',
        },
        {
          label: '～10件',
          value: `0${splt}10`,
        },
        {
          label: '11～30件',
          value: `11${splt}30`,
        },
        {
          label: '31～50件',
          value: `31${splt}50`,
        },
        {
          label: '51件～',
          value: `51${splt}99999`,
        },
      ],
      importanceItem: '高',
      systemCategoryItems: [
        {
          label: '停止予定',
          class: 'schedule',
        },
        {
          label: '障害発生中',
          class: 'failure',
        },
        {
          label: '障害回復',
          class: 'recovery',
        },
        {
          label: 'お知らせ',
          class: 'info',
        },
      ],
      statusItems: [
        {
          label: '公開中',
          class: 'publish',
        },
        {
          label: '下書き',
          class: 'draft',
        },
        {
          label: '公開予約',
          class: 'reserved',
        },
        {
          label: '公開終了',
          class: 'closed',
        },
      ],
      contentsType: {
        information: 'info',
        chat: 'cht',
      },
    };
  },
  async created() {
    let data = this.$root.masterInfo;
    if (Array.isArray(data.m_information_category)) {
      this.infoCategoryItems = data.m_information_category.map((x) => {
        return {
          id: x.information_category_id,
          label: x.information_category_name,
        };
      });
    }
    if (Array.isArray(data.m_chat_category)) {
      this.chatCategoryItems = data.m_chat_category.map((x) => {
        return {
          id: x.category_id,
          label: x.category_name,
          icon: x.icon_class,
        };
      });
      this.chatCategoryItems.unshift({
        id: '0',
        label: '選択してください',
        icon: '',
      });
    }
    if (Array.isArray(data.m_organization)) {
      this.organizationItems = data.m_organization.map((x) => {
        return {
          id: x.organization_code,
          label: x.organization_shortname,
        };
      });
    }
  },
  computed: {
    categoryIcon() {
      return function (id) {
        let item = this.chatCategoryItems.find((x) => x.id == id);
        return item ? item.icon : '';
      };
    },
    chatCategoryLabel() {
      return function (id) {
        return this.itemLabel(this.chatCategoryItems, id);
      };
    },
    organizationId() {
      return function (label) {
        return this.itemId(this.organizationItems, label);
      };
    },
    organizationLabel() {
      return function (id) {
        return this.itemLabel(this.organizationItems, id);
      };
    },
    infoCategoryId() {
      return function (label) {
        return this.itemId(this.infoCategoryItems, label);
      };
    },
    infoCategoryLabel() {
      return function (id) {
        return this.itemLabel(this.infoCategoryItems, id);
      };
    },
    itemId() {
      return function (items, label) {
        let item = items.find((x) => x.label == label);
        return item ? item.id : '0';
      };
    },
    itemLabel() {
      return function (items, id) {
        let item = items.find((x) => x.id == id);
        return item ? item.label : '';
      };
    },
    importanceFlag() {
      return function (label) {
        return label == '1';
      };
    },
    sysCategoryClass() {
      return function (label) {
        let item = this.systemCategoryItems.find((x) => x.label == label);
        return item ? item.class : '';
      };
    },
    statusId() {
      return function (label) {
        return this.statusItems[0] == label ? 1 : 0;
      };
    },
    statusClass() {
      return function (label) {
        let item = this.statusItems.find((x) => x.label == label);
        return item ? item.class : '';
      };
    },
  },
};
