<template>
  <nav class="page_nav setKeywordWatch">
    <h2>キーワードウォッチ登録</h2>
    <div class="form_group">
      <input
        type="text"
        v-model.trim="keyword"
        placeholder="キーワードを入力"
        class="type_keyword"
      />
      <button class="btn" @click="registerKW()" :disabled="vld.$invalid">
        キーワード登録
      </button>
    </div>
  </nav>
  <ListFrame
    ref="list"
    title="ウォッチ登録|キーワード"
    :totalCount="totalCount"
    :action="setList"
    :hideCount="true"
    class="list_result setKeywordWatch"
    :showLimit="false"
    :showPager="false"
  >
    <div class="result_contents">
      <nav class="sort">
        <div class="sort_item keyword" data-sort="keyword">
          <h3>キーワード</h3>
        </div>
      </nav>

      <div class="setKeywordList">
        <div class="setKeywordList_item" v-for="item of items" :key="item.id">
          <h4>{{ item.keyword }}</h4>
          <p>
            <a
              @click.prevent="deleteKW(item.keyword)"
              href="#"
              class="btn type_cancel"
              title="削除"
              >削除</a
            >
          </p>
        </div>
      </div>
    </div>
  </ListFrame>
</template>
<script>
import view from '../mixin/view';
import api from '../services/api';
import dataset from '../mixin/dataset';
import ListFrame from '../components/ListFrame.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
export default {
  name: 'ChatList',
  components: { ListFrame },
  setup() {
    return { vld: useVuelidate() };
  },
  data() {
    return { keyword: '' };
  },
  mixins: [view, dataset],
  validations() {
    return {
      keyword: { required },
    };
  },
  methods: {
    async getData(params) {
      return await api.getKeywordWatchList(params);
    },
    async registerKW() {
      let param = { keyword: this.keyword };
      let ret = await api.registerKeywordWatch(param);
      if (ret.response.statusCode == 202) {
        await this.showInfo('同じキーワードが登録されています');
        return;
      }
      if (ret.response.statusCode == 203) {
        await this.showInfo('キーワードの登録は10件までとなっています');
        return;
      }
      this.gaEvent('keyword_watch_register', { keyword: this.keyword });
      this.keyword = '';
      this.setList();
      await this.showInfo('登録しました');
    },
    async deleteKW(kw) {
      let ret = await this.confirm();
      if (!ret) return;
      let param = { keyword: kw, deleteFlag: '1' };
      await api.registerKeywordWatch(param);
      this.setList();
    },
  },
  created() {
    this.setList({ orders: '-update_datetime' }, true);
  },
};
</script>
