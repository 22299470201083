<template>
  <HeaderFrame :userInfo="userInfo" :menu="menuInfo" ref="header" />
  <main>
    <router-view />
  </main>
  <footer class="g_footer">
    <nav>
      <ul>
        <li>株式会社ノーリツ</li>
        <li>
          <a
            :href="privateUseUrl"
            target="_blank"
            @click="
              gaEvent('global_menu', {
                title: 'グループ内個人情報の共同利用について',
              })
            "
            >グループ内個人情報の共同利用について</a
          >
        </li>
        <li>
          <a
            :href="aboutUseStite"
            target="_blank"
            @click="gaEvent('global_menu', { title: 'サイトのご利用について' })"
            >サイトのご利用について</a
          >
        </li>
      </ul>
    </nav>
    <address>
      <p>Copyright &copy; NORITZ Corporation All Rights Reserved</p>
    </address>
  </footer>
  <p class="pagetop">
    <a
      ><img
        src="@/assets/img/common/i_pagetop.svg"
        alt="ページトップ"
        class="img_responsive"
    /></a>
  </p>
</template>

<script>
// @ is an alias to /src
import { useHead } from '@vueuse/head';
import HeaderFrame from './components/HeaderFrame.vue';

export default {
  components: {
    HeaderFrame,
  },
  setup() {
    useHead({
      script: [
        {
          async: true,
          src: `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GA_ID}`,
        },
      ],
    });
  },
  async created() {
    window.dataLayer = window.dataLayer || [];
    this.gtag('js', new Date());
  },
  data() {
    return {
      userInfo: {},
      masterInfo: {},
      menuInfo: {},
    };
  },
  computed: {
    privateUseUrl() {
      return this.menuInfo.personal_info_link;
    },
    aboutUseStite() {
      return this.menuInfo.site_link;
    },
  },
  mounted() {
    document.title = 'NORNET';
  },
  methods: {
    setUserInfo(info) {
      this.userInfo = {
        name: `${info.family_name} ${info.first_name}`,
        orgCode: info.organization_code,
        orgName: info.organization_shortname,
        grade: info.grade,
        office: info.office,
        rank: info.rank,
      };
      this.setGtagOption(this.userInfo);
    },
  },
};
</script>

<style lang="scss">
@import './assets/css/style.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.btn:disabled,
.btn:disabled:hover {
  background: #ccc;
  color: #707070;
}
.txt_contents {
  white-space: pre-wrap;
}

.container {
  overflow: hidden;
  width: 100%;
  .ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  .ellipsis2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
.container a,
.container dd,
.container p,
.container strong {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
button.owl-prev > span,
button.owl-next > span {
  visibility: hidden;
}

.ellipsis {
  margin: 0;
}
.ui-dialog {
  z-index: 1020;
}
.paging ul li.disabled a:hover {
  color: rgba(112, 112, 112, 0.4);
}
img {
  object-fit: cover;
  width: auto;
}
.ui-widget-overlay.ui-front {
  z-index: 1010;
}
.primaryContents .top_NORNET-TV .col_tv .movieArea {
  padding-top: 0%;
}
@media screen and (max-width: 480px) {
  .g_footer nav ul li + li {
    text-align: left;
    padding-left: 30px;
  }
  .g_footer nav ul li a {
    display: block;
  }
}
.noAuth {
  color: red;
  text-align: center;
  vertical-align: middle;
  padding: 35px;
  font-size: 22px;
}
.list_result.detail_news .newsDetail_contents .main_contents p {
  margin: 0 auto 0px;
}
.txt_contents table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px double #b3b3b3;
  margin: 0.9em auto;
  display: table;
}
.txt_contents table td {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid #bfbfbf;
}
.txt_contents {
  .text-tiny {
    font-size: 0.7em;
  }
  .text-small {
    font-size: 0.85em;
  }
  .text-big {
    font-size: 1.4em;
  }
  .text-huge {
    font-size: 1.8em;
  }
}
.tag_system.info {
  background: #51c5b8;
}
</style>
