import { createRouter, createWebHistory } from 'vue-router';
import authentication from '../services/authentication';
import store from '../store/index';
import Top from '../views/Top.vue';
import TopicsList from '../views/TopicsList.vue';
import Chat from '../views/Chat.vue';
import ChatList from '../views/ChatList.vue';
import InformationList from '../views/InformationList.vue';
import InformationPost from '../views/InformationPost.vue';
import Information from '../views/Information.vue';
import SystemInfo from '../views/SystemInfo.vue';
import Page from '../views/Page.vue';
import KeywordWatchList from '../views/KeywordWatchList.vue';
import WatchList from '../views/WatchList.vue';
import RankingList from '../views/RankingList.vue';
import NotFound from '../views/NotFound.vue';
import Login from '../views/Login.vue';

const routes = [
  {
    path: '/',
    component: Top,
    props: { bodyclass: 'top' },
  },
  {
    path: '/topicsList',
    component: TopicsList,
  },
  {
    path: '/detail_chat',
    component: Chat,
  },
  {
    path: '/list_chat',
    component: ChatList,
  },
  {
    path: '/list_news',
    component: InformationList,
  },
  {
    path: '/post_news',
    component: InformationPost,
  },
  {
    path: '/detail_news',
    component: Information,
    props: { bodyclass: 'news' },
  },
  {
    path: '/detail_system',
    component: SystemInfo,
    props: { bodyclass: 'system' },
  },
  {
    path: '/detail_page',
    component: Page,
    props: { bodyclass: 'page' },
  },
  {
    path: '/setting_keywordWatch',
    component: KeywordWatchList,
  },
  {
    path: '/list_watch',
    component: WatchList,
  },
  {
    path: '/list_ranking',
    component: RankingList,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.VUE_APP_BASE_URL),
  // base: process.env.VUE_APP_BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let queryParams = [];
  switch (to.path) {
    case '/login':
      next();
      return;
    case '/detail_chat':
    case '/detail_news':
    case '/detail_page':
    case '/detail_system':
      queryParams = ['id'];
      if (!to.query[queryParams[0]]) {
        next({ path: '/not_found' });
        return;
      }
      break;
  }
  if (Object.keys(to.query).length != queryParams.length) {
    next({ path: '/not_found' });
    return;
  }
  let href = await authentication.check();
  if (store.state.hashValue && to.path == '/') {
    to.fullPath = to.path;
  }
  if (href && href != to.path) {
    //URLパラメータからクエリをセットする
    let arr = href.split('?');
    let query = {};
    if (arr.length == 2) {
      let arr2 = arr[1].split('=');
      query[arr2[0]] = arr2[1];
    }
    //リダイレクト
    next({ path: href || '/', query: query });
    return;
  }
  if (from.matched.length == 0) {
    //リロードされた場合はログイン画面を経由する
    store.commit('setTempParams', to.fullPath);
    next({ path: '/login' });
    return;
  }
  next();
});

export default router;
