<template>
  <section class="loadData">
    <div class="loader"><span></span></div>
    <header>
      <div class="title">
        <h2>
          <template v-if="titleStr.length == 2">
            {{ titleStr[0] }}<br class="hidden_PC hidden_TAB" />{{
              titleStr[1]
            }}一覧
          </template>
          <template v-else> {{ titleStr[0] }}一覧</template>
        </h2>
        <p class="count" v-show="!hideCount">
          {{ totalCount }}<span class="unit">件</span>
        </p>
      </div>
      <!--/title-->
      <div class="form_group view_count" v-if="showLimit">
        <h3>表示：</h3>
        <select @change="changeLimit" v-model="pageLimit">
          <option
            v-for="item of pageLimitItems"
            :key="item.key"
            :value="item.key"
          >
            {{ item.key }} 件
          </option>
        </select>
      </div>
      <!--/form_group-->
    </header>

    <slot />

    <nav class="paging">
      <ol v-show="totalCount == 0" style="color: pink">
        表示する明細がありません
      </ol>
      <ol v-show="totalCount > 0 && showPager">
        <li class="prev">
          <a href="#" @click.prevent="prev" title="前へ"></a>
        </li>
        <li
          v-for="(page, idx) of pages"
          :key="idx"
          :class="current(page) ? `current` : ``"
        >
          <a
            v-show="!nolink(page)"
            :href="page"
            @click.prevent="paging(page)"
            >{{ page }}</a
          >
          <a v-show="nolink(page)" :class="isNaN(page) ? 'nolink' : ''">{{
            page
          }}</a>
        </li>
        <li class="next">
          <a href="#" @click.prevent="next" title="次へ"></a>
        </li>
      </ol>
    </nav>
  </section>
</template>

<script>
let pageLimit = process.env.VUE_APP_PAGE_LIMIT;
import { splt } from '../mixin/constants';
export default {
  name: 'ListFrame',
  props: {
    title: { default: '検索結果' },
    totalCount: { default: 0 },
    action: {
      type: Function,
      require: true,
    },
    showLimit: { default: true },
    showPager: { default: true },
    hideCount: { default: false },
  },

  mounted() {
    let self = this;
    var sortitems = document.querySelectorAll('.sort_item[data-sort]');
    sortitems.forEach(function (target) {
      target.addEventListener('click', function () {
        //ソート処理
        let classList = target.classList;
        let todesc = classList.contains('asc'); //昇順 → 降順
        classList.remove('asc', 'desc');
        if (todesc) {
          classList.add('desc');
        } else {
          classList.add('asc');
        }
        self.action({ orders: `${todesc ? '-' : ''}${target.dataset.sort}` });
      });
    });
  },
  data() {
    return {
      pageNo: 1,
      pageLimit: pageLimit,
    };
  },
  computed: {
    titleStr() {
      return this.title.split('|');
    },
    maxPage() {
      return Math.ceil(this.totalCount / this.pageLimit);
    },
    current() {
      return (page) => {
        return page == this.pageNo;
      };
    },
    nolink() {
      return (page) => {
        return this.current(page) || isNaN(page);
      };
    },
    pages() {
      let displayCount = 7; //表示するページ番号の数（7以上の奇数）
      if (this.maxPage < displayCount + 1) {
        return [...Array(this.maxPage)].map((_, i) => i + 1);
      }
      let range = displayCount - 2;
      //先頭ページ
      let pages = [1];
      //先頭ページ１つ後
      let pg = this.pageNo - range / 2;
      pg = Math.floor(pg);
      pg = Math.max(1, pg);
      pg = Math.min(this.maxPage - 1 - range, pg);
      pages.push(++pg == 2 ? 2 : '...');
      //中間のページ
      while (pages.length < range) {
        pages.push(++pg);
      }
      //最終ページ１つ前
      pages.push(++pg == this.maxPage - 1 ? this.maxPage - 1 : '...');
      //最終ページ
      pages.push(this.maxPage);
      return pages;
    },
  },

  methods: {
    paging(nextPage) {
      if (this.current(nextPage)) return;
      if (isNaN(nextPage)) return;
      this.pageNo = nextPage;
      this.fetch();
    },
    prev() {
      if (this.pageNo < 2) return;
      this.pageNo--;
      this.fetch();
    },
    next() {
      if (this.pageNo >= this.maxPage) return;
      this.pageNo++;
      this.fetch();
    },
    fetch() {
      let params = { pageLimit: this.pageLimit, pageNumber: this.pageNo };
      this.action(params);
      //ページ先頭へ移動
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    },
    changeLimit() {
      this.pageNo = 1;
      this.fetch();
    },
    setSortState(orders) {
      if (!orders) return;
      let arr = orders.split(splt);
      var sortitems = document.querySelectorAll('.sort_item[data-sort]');
      sortitems.forEach(function (target) {
        let odr = arr.find((x) => x.replace('-', '') == target.dataset.sort);
        if (odr) {
          let classList = target.classList;
          classList.remove('asc', 'desc');
          if (odr.startsWith('-')) {
            classList.add('desc');
          } else {
            classList.add('asc');
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.paging ol li a:hover.nolink {
  background: inherit;
  color: inherit;
}
</style>
