import common from './apiCommon';

export default {
  async getMenu(params) {
    return await common.getData('getMenu', params);
  },
  async getMasterList(params) {
    return await common.getData('getMasterList', params);
  },
  async getTopics(params) {
    return await common.getData('getTopicsList', params);
  },
  async getChatList(params) {
    return await common.getData('getChatList', params);
  },
  async postChat(params) {
    return await common.post('registerChatFirst', params);
  },
  async getChatDetail(params) {
    return await common.getData('getChatDetail', params);
  },
  async postComment(params) {
    return await common.post('registerChatComment', params);
  },
  async editChat(params) {
    return await common.post('editChatFirst', params);
  },
  async editComment(params) {
    return await common.post('editChatComment', params);
  },
  async registerChatBestAnswer(params) {
    return await common.post('registerChatBestAnswer', params);
  },
  async registerChatClosed(params) {
    return await common.post('registerChatClosed', params);
  },
  async getInformationList(params) {
    return await common.getData('getInformationList', params);
  },
  async getInformationDetail(params) {
    return await common.getData('getInformationDetail', params);
  },
  async postInformation(params) {
    return await common.post('registerInformation', params);
  },
  async editInformation(params) {
    return await common.post('editInformation', params);
  },
  async registerInformationFile(params) {
    return await common.post('registerInformationFile', params);
  },
  async editLike(params) {
    return await common.post('editLike', params);
  },
  async getSystemList(params) {
    return await common.getData('getSystemList', params);
  },
  async getSystemDetail(params) {
    return await common.getData('getSystemDetail', params);
  },
  async getPageDetail(params) {
    return await common.getData('getPageDetail', params);
  },
  async getKeywordWatchList(params) {
    return await common.getData('getKeywordWatchList', params);
  },
  async registerKeywordWatch(params) {
    return await common.post('registerKeywordWatch', params);
  },
  async getWatchList(params) {
    return await common.getData('getWatchList', params);
  },
  async registerInformationWatch(params) {
    return await common.post('registerInformationWatch', params);
  },
  async getInformationRanking(params) {
    return await common.getData('getInformationRanking', params);
  },
  async getSignedUrl(params) {
    return await common.getData('getSignedUrl', params);
  },
  async registerChatFile(params) {
    return await common.post('registerChatFile', params);
  },
  async getYulifeList(params) {
    return await common.getData('getYulifeList', params);
  },
  async login(params) {
    return await common.post('login', params);
  },
  async getUserInfo(params) {
    return await common.getData('getUserInfo', params);
  },
};
