<template>
  <div
    class="file_item"
    :class="file.image_flag ? 'image' : 'default'"
    :data-fn="file.file_name"
  >
    <a :href="filePath" @click.prevent="download(file)">
      <template v-if="file.image_flag">
        <figure>
          <img :src="filePath" :alt="file.file_name" class="img_responsive" />
          <figcaption>
            {{ file.file_name }}<span>（{{ toKB(file.file_size) }}KB）</span>
          </figcaption>
        </figure>
      </template>
      <template v-else>
        {{ file.file_name }}<span>（{{ toKB(file.file_size) }}KB）</span>
      </template>
    </a>
  </div>
  <div v-if="showDel" class="file_item_delete">
    <a class="asideIcon delete" :href="filePath" @click.prevent="delFile()"
      >削除</a
    >
  </div>
</template>

<script>
export default {
  emits: ['delete'],
  props: {
    file: { default: { deleted: false } },
    showDel: { default: false },
    canDownload: { default: false },
    title: { default: '' }, //"canDownload: true"の場合は設定が必要
  },
  data() {
    return { kind: 'cht' };
  },
  computed: {
    filePath() {
      return this.getFileUrl(this.file.file_path + '/' + this.file.file_name);
    },
  },
  methods: {
    async download() {
      if (!this.canDownload) return;
      await this.downloadFile(
        this.kind,
        this.file.file_path.replace('/', '|'),
        this.file.file_name,
        this.title
      );
    },
    delFile() {
      this.$emit('delete');
    },
  },
};
</script>
