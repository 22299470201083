export default {
  props: {
    title: { default: '' },
    action: {
      type: Function,
      require: true,
    },
  },

  data() {
    return {
      gaEventName: '',
      keyword: '',
      recoveryParams: null,
    };
  },
  created() {
    this.recoveryParams = this.$store.getters.getSaveParams(this.$route.path);
    if (this.recoveryParams) {
      this.recoverParam(this.recoveryParams);
    }
  },
  methods: {
    createParam() {
      return {};
    },

    recoverParam() {
      return;
    },

    search() {
      let modal = () => this.modalShowing();
      //データ取得処理
      let params = this.createParam();
      this.action(params, true, this.recoveryParams);
      this.recoveryParams = null;
      if (modal()) this.closeModal();
      if (this.gaEventName != '' && this.keyword != '') {
        this.gaEvent(this.gaEventName, { keyword: this.keyword });
      }
    },
    getParams(idList) {
      let params = {};
      for (let id of idList) {
        let elm = document.getElementById(id);
        if (elm && !this.isEmpty(elm.value))
          if (id.indexOf('date') > -1 || id.indexOf('Date') > -1) {
            params[id] = elm.value.replaceAll('/', '-');
          } else {
            params[id] = elm.value;
          }
      }
      return params;
    },
  },
};
