<template>
  <section class="loadData list_result watch">
    <nav class="page_nav watch">
      <h2>ウォッチ一覧</h2>
      <nav>
        <ul class="tabNav">
          <li><a href="#tab_01" class="btn watchCategory">お知らせ</a></li>
          <li><a href="#tab_03" class="btn watchCategory">チャット</a></li>
        </ul>
      </nav>
      <div class="form_group view_count">
        <h3>表示：</h3>
        <select @change="changeLimit" v-model="pageLimit">
          <option
            v-for="item of pageLimitItems"
            :key="item.key"
            :value="item.key"
          >
            {{ item.key }} 件
          </option>
        </select>
      </div>
    </nav>

    <div class="tabcontent watch">
      <div class="loader"><span></span></div>
      <div id="tab_01" class="tabBox">
        <Information ref="info" />
      </div>
      <div id="tab_03" class="tabBox">
        <Chat ref="chat" />
      </div>
    </div>
  </section>
</template>
<script>
import view from '../mixin/view';
import ListFrame from '../components/ListFrame.vue';
import Information from '../components/watchList/Information.vue';
import Chat from '../components/watchList/Chat.vue';
export default {
  mixins: [view, ListFrame],
  components: { Information, Chat },
  methods: {
    changeLimit() {
      let params = { pageLimit: this.pageLimit, pageNumber: 1 };
      this.$refs.info.setList(params);
      this.$refs.chat.setList(params);
    },
  },
  mounted() {
    this.showTabNav();
  },
};
</script>
<style scoped>
.page_nav.watch nav ul li {
  width: calc(calc(100% - 60px) / 2);
}
.page_nav.watch nav {
  width: 400px;
  margin-right: 100px;
}
@media screen and (max-width: 480px) {
  .page_nav.watch nav {
    margin-right: 0px;
  }
}
</style>
