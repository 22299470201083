/* eslint-disable no-undef */
import extend from './extend';

export default function common() {
  $(function () {
    extend();

    var num = 10;
    var num_sp = 10;

    //pagetop
    var flag = false;
    var pagetop = $('.pagetop');
    $(window).scroll(function () {
      var windowWidth = $(window).width();
      if (windowWidth < 768) {
        if ($(this).scrollTop() > num_sp) {
          if (flag == false) {
            flag = true;
            pagetop.stop().animate(
              {
                bottom: '140px',
              },
              200
            );
          }
        } else {
          if (flag) {
            flag = false;
            pagetop.stop().animate(
              {
                bottom: '-250px',
              },
              200
            );
          }
        }
      } else {
        if ($(this).scrollTop() > num) {
          if (flag == false) {
            flag = true;
            pagetop.stop().animate(
              {
                bottom: '78px',
              },
              200
            );
          }
        } else {
          if (flag) {
            flag = false;
            pagetop.stop().animate(
              {
                bottom: '-250px',
              },
              200
            );
          }
        }
      }
    });
    pagetop.click(function () {
      $('body, html').animate({ scrollTop: 0 }, 100);
      return false;
    });

    $('.datePick').datepicker({
      dateFormat: 'yy/mm/dd',
      //クリアボタン追加
      onSelect: function () {
        let value = $(this).val();
        let e = new Event('input', {
          bubbles: true,
          cancelable: true,
        });
        e.value = value;
        this.dispatchEvent(e);
        if (value == null || value == '') {
          $(this).next('.dateClear').remove();
          $(this).unwrap();
        } else {
          //日付選択重複の場合があるので
          if ($(this).parents('.wrapDate').length) {
            $(this).next('.dateClear').remove();
            $(this).unwrap();
          }
          $(this)
            .wrap("<div class='wrapDate'></div>")
            .after('<span class="dateClear"></span>');
        }
      },
    });

    $(document).on('click', '.dateClear', function () {
      var targetDate = $(this).prev('.datePick');
      $.datepicker._clearDate(targetDate);
    });

    let changeDate = (obj) => {
      let value = $(obj).val();
      let e = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      e.value = value;
      obj.dispatchEvent(e);
      if (value == null || value == '') {
        $(obj).next('.dateTimeClear').remove();
        $(obj).unwrap();
      } else if ($(obj).parents('.wrapDate').length) {
        $(obj).next('.dateTimeClear').remove();
        $(obj).unwrap();
        $(obj)
          .wrap("<div class='wrapDate'></div>")
          .after('<span class="dateTimeClear"></span>');
      } else {
        $(obj)
          .wrap("<div class='wrapDate'></div>")
          .after('<span class="dateTimeClear"></span>');
      }
    };
    $('.dateTimePick').datetimepicker({
      timeInput: true,
      timeFormat: 'HH:mm',
      closeText: '閉じる',
      currentText: '現在日時を設定',
      timeText: '時間',
      hourText: '時',
      minuteText: '分',
      onSelect: function () {
        changeDate(this);
      },
      afterInject: function () {
        $(`#${this.$input[0].id}`).change(function () {
          changeDate(this);
        });
      },
    });

    $(document).on('click', '.dateTimeClear', function () {
      var targetDate = $(this).prev('.dateTimePick');
      $.datepicker._clearDate(targetDate);
    });

    $(window).on('scroll', function () {
      var viewHeight = $(window).innerHeight();
      var contentsHeight = $('body').get(0).scrollHeight;
      var headerHeight = $('.g_header').outerHeight();
      var scrollHeight = contentsHeight - viewHeight;
      var windowWidth_for_scroll = $(window).width();
      if (windowWidth_for_scroll < 768) {
        if ($(window).scrollTop() > 0) {
          $('.g_header').addClass('scrolled');
        } else {
          $('.g_header').removeClass('scrolled');
        }
      } else {
        if (scrollHeight > headerHeight * 2) {
          if ($(window).scrollTop() > headerHeight) {
            $('.g_header').addClass('scrolled');
          } else {
            $('.g_header').removeClass('scrolled');
          }
        } else {
          $('.g_header').removeClass('scrolled');
        }
      }
    });
  });
}
