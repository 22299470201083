import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import utils from './mixin/utils';
import constants from './mixin/constants';
import Toaster from '@meforma/vue-toaster';
import CKEditor from '@ckeditor/ckeditor5-vue';
import { createHead } from '@vueuse/head';
import sanitizeHTML from 'sanitize-html';

const head = createHead();
let customPlugin = {
  install: (app) => {
    sanitizeHTML.defaults.allowedAttributes.span = ['style', 'class'];
    sanitizeHTML.defaults.allowedAttributes.p = ['style'];
    sanitizeHTML.defaults.allowedTags.push('img');
    app.config.globalProperties.$sanitize = sanitizeHTML;
  },
};

createApp(App)
  .use(head)
  .use(store)
  .use(router)
  .use(Toaster)
  .use(CKEditor)
  .use(customPlugin)
  .mixin(utils)
  .mixin(constants)
  .mount('#app');
