<template>
  <SearchFrame title="お知らせ" kind="news">
    <SearchFrameInformation title="お知らせ" ref="search" :action="setList" />
  </SearchFrame>
  <ListFrame
    ref="list"
    :totalCount="totalCount"
    :action="setList"
    class="list_result news"
  >
    <div class="result_contents">
      <nav class="sort">
        <div class="sort_item category" data-sort="category">
          <h3>カテゴリー</h3>
        </div>
        <div class="sort_item importance" data-sort="importance">
          <h3>重要度</h3>
        </div>
        <div class="sort_item date" data-sort="publish_start_date">
          <h3>日付</h3>
        </div>
        <div class="sort_item title" data-sort="title">
          <h3>タイトル</h3>
        </div>
        <!--スマホの表示箇所調整用のdivです。中身はないけど消さないで欲しいです。-->
        <div class="sp_space"></div>
        <!--スマホの表示箇所調整用のdivです。中身はないけど消さないで欲しいです。-->
        <div class="sp_space"></div>
        <div class="sort_item group" data-sort="department_name">
          <h3>部門</h3>
        </div>
        <div class="sort_item good" data-sort="like_number">
          <h3>
            <img src="@/assets/img/common/icon_good_gray.svg" alt="いいね" />
          </h3>
        </div>
        <div class="sort_item watch" data-sort="watch_flag">
          <h3>
            <img
              src="@/assets/img/common/icon_watch_gray.svg"
              alt="ウォッチリスト"
            />
          </h3>
        </div>
      </nav>

      <div
        class="result_contents_item"
        :class="`${item.read_flag ? 'unread' : ''}`"
        v-for="item of items"
        :key="item.information_id"
      >
        <router-link :to="`/detail_news?id=${item.information_id}`">
          <div class="category">
            <p>
              <span class="news_category">{{ item.category }}</span>
            </p>
          </div>
          <div class="importance">
            <p>
              <span
                class="asideIcon important"
                :class="importanceFlag(item.importance_id) ? 'high' : ''"
              ></span>
            </p>
          </div>
          <div class="date">
            <p>{{ fmtymdS(item.publish_start_date) }}</p>
          </div>
          <div class="title">
            <h3>{{ item.title }}</h3>
            <p class="attachedFile">
              <span v-show="item.file_flag" class="icon attached" />
            </p>
          </div>
          <div class="group">
            <p>{{ item.department_name }}</p>
          </div>
          <div class="good">
            <p class="asideIcon good type_gray">{{ item.like_number }}</p>
          </div>
          <div class="watch">
            <p>
              <span
                class="asideIcon"
                :class="item.watch_flag ? 'watch' : 'unwatch'"
              ></span>
            </p>
          </div>
        </router-link>
      </div>
    </div>
  </ListFrame>
</template>
<script>
import view from '../mixin/view';
import dataset from '../mixin/dataset';
import SearchFrame from '../components/SearchFrame.vue';
import SearchFrameInformation from '../components/SearchFrameInformation.vue';
import ListFrame from '../components/ListFrame.vue';
import api from '../services/api';

export default {
  name: 'InformationList',
  components: { SearchFrame, SearchFrameInformation, ListFrame },
  mixins: [view, dataset],
  methods: {
    async getData(params) {
      if (!params.orders) params.orders = '-publish_start_date';
      return await api.getInformationList(params);
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (to.path.startsWith('/detail_news')) {
      await this.setIdList('information_id');
    }
    next();
  },
  mounted() {
    this.conditionSearch();
  },
};
</script>
