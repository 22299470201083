<template>
  <div class="title">
    <h2 v-if="!isSPMode">{{ title }}一覧</h2>
    <div class="read">
      <h3>既読：</h3>
      <ul>
        <li>
          <input
            id="unread"
            type="checkbox"
            v-model="unreadFlag"
            class="check_tag"
          />
          <label for="unread" :class="unreadFlag ? 'on' : ''">未読</label>
        </li>
        <li>
          <input
            id="read"
            type="checkbox"
            v-model="readFlag"
            class="check_tag"
          />
          <label for="read" :class="readFlag ? 'on' : ''">既読</label>
        </li>
      </ul>
    </div>
  </div>
  <!--/title-->
  <div class="category_importance_keyword">
    <div class="category tags">
      <h3>カテゴリー：</h3>
      <ul>
        <li v-for="item of infoCategoryItems" :key="item.id">
          <input
            type="checkbox"
            :id="`category_${item.id}`"
            v-model="checkedCategories"
            v-bind:value="item.id"
            class="check_tag"
          />
          <label
            :for="`category_${item.id}`"
            :class="checkedCategories.find((x) => x == item.id) ? 'on' : ''"
            >{{ item.label }}</label
          >
        </li>
      </ul>
    </div>
    <div class="importance">
      <h3>重要度：</h3>
      <ul>
        <li>
          <input
            type="checkbox"
            id="check_importance_1"
            class="check_tag check_importance"
            v-model="importance"
          />
          <label for="check_importance_1" :class="importance ? 'on' : ''"
            >高</label
          >
        </li>
      </ul>
    </div>

    <div class="keyword">
      <input
        v-model.trim="keyword"
        type="text"
        placeholder="キーワードを入力"
        class="type_keyword"
      />
    </div>
  </div>
  <div class="metaData">
    <div class="period">
      <div class="period_date">
        <h3>期間：</h3>
        <div class="form_group">
          <DatePicker
            id="postingDateFrom"
            :value="dateFrom"
            @setValid="setValid"
          />
          <span class="separate">ー</span>
          <DatePicker
            id="postingDateTo"
            :value="dateTo"
            @setValid="setValid2"
          />
        </div>
      </div>
    </div>
    <div class="good">
      <h3>イイね：</h3>
      <select v-model="likeNumber">
        <option
          v-for="item of goodCountRangeItems"
          :value="item.value"
          :key="item.label"
        >
          {{ item.label }}
        </option>
      </select>
    </div>
    <div class="search">
      <button class="btn" @click="search" :disabled="dateInvalid">検索</button>
    </div>
  </div>
</template>

<script>
import search from '../mixin/search';
import DatePicker from './DatePicker';
import { splt } from '../mixin/constants';
export default {
  name: 'SearchFrameChat',
  mixins: [search],
  components: { DatePicker },

  data() {
    return {
      checkedCategories: [],
      dateFrom: this.getFromDate(),
      dateTo: this.fmtymd(),
      unreadFlag: false,
      readFlag: false,
      categoryId: 0,
      likeNumber: '',
      replyNumber: '',
      importance: false,
      gaEventName: 'notice_search',
    };
  },
  methods: {
    createParam() {
      let dtlist = ['postingDateFrom', 'postingDateTo'];
      let params = this.getParams(dtlist);
      params.keyword = this.toHalfSpace(this.keyword);
      if (this.readFlag == this.unreadFlag) {
        delete params.readFlag;
      } else {
        if (this.unreadFlag) params.readFlag = 0;
        if (this.readFlag) params.readFlag = 1;
      }
      if (this.importance) params.importance = '1';
      params.category = this.checkedCategories.join(splt);
      params.likeNumber = this.likeNumber;
      return params;
    },
    recoverParam(prm) {
      if (prm) {
        this.keyword = prm.keyword;
        this.dateFrom = prm.postingDateFrom;
        this.dateTo = prm.postingDateTo;
        this.unreadFlag = prm.unreadFlag;
        this.readFlag = prm.readFlag;
        this.checkedCategories = prm.category ? prm.category.split(splt) : [];
        this.likeNumber = prm.likeNumber;
        this.replyNumber = prm.replyNumber;
        this.importance = prm.importance;
      }
    },
  },
};
</script>
