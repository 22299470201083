<template>
  <SearchFrame title="トピックス">
    <SearchFrameTopics title="トピックス" ref="search" :action="setList" />
  </SearchFrame>
  <ListFrame
    ref="list"
    :totalCount="totalCount"
    :action="setList"
    class="list_result topics"
  >
    <div class="result_contents">
      <nav class="sort">
        <div class="title hidden_SP">
          <h3>タイトル</h3>
        </div>
        <div class="group hidden_SP">
          <h3>部門</h3>
        </div>
        <div class="sort_item date" data-sort="publish_start_date">
          <h3>日付</h3>
        </div>
        <div class="description">
          <h3>概要</h3>
        </div>
      </nav>

      <div
        class="result_contents_item"
        :class="`${item.read_flag ? 'unread' : ''}`"
        v-for="item of items"
        :key="item.id"
      >
        <a
          :href="item.url"
          target="_blank"
          @click="
            gaEvent('topics_detail', {
              title: item.title,
              group: item.department_name,
              date: item.publish_start_date,
            })
          "
        >
          <figure>
            <template v-if="item.image && item.image.url">
              <img :src="item.image.url" class="img_responsive" />
            </template>
            <template v-else>
              <img
                src="@/assets/img/topics/i_noImage.png"
                class="img_responsive"
              />
            </template>
          </figure>
          <div>
            <div class="title">
              <h3>{{ item.title }}</h3>
            </div>
            <div class="group">
              <p>{{ item.department_name }}</p>
            </div>
            <div class="date">
              <p>{{ fmtymdS(item.publish_start_date) }}</p>
            </div>
            <div class="description">
              <!--★PCとSPで文字数のトリミング位置が違うっぽい??-->
              <p>{{ item.overview }}</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </ListFrame>
</template>
<script>
import view from '../mixin/view';
import dataset from '../mixin/dataset';
import SearchFrame from '../components/SearchFrame.vue';
import SearchFrameTopics from '../components/SearchFrameTopics.vue';
import ListFrame from '../components/ListFrame.vue';
import api from '../services/api';

export default {
  name: 'TopicsList',
  components: { SearchFrame, SearchFrameTopics, ListFrame },
  mixins: [view, dataset],
  methods: {
    async getData(params) {
      return await api.getTopics(params);
    },
  },
  mounted() {
    this.conditionSearch();
  },
};
</script>
