<template>
  <div class="title">
    <h2 v-if="!isSPMode">{{ title }}一覧</h2>
    <div class="read">
      <h3>既読：</h3>
      <ul>
        <li>
          <input
            id="unread"
            type="checkbox"
            v-model="unreadFlag"
            class="check_tag"
          />
          <label for="unread" :class="unreadFlag ? 'on' : ''">未読</label>
        </li>
        <li>
          <input
            id="read"
            type="checkbox"
            v-model="readFlag"
            class="check_tag"
          />
          <label for="read" :class="readFlag ? 'on' : ''">既読</label>
        </li>
      </ul>
    </div>
  </div>
  <div class="category_keyword">
    <div class="category">
      <h3>カテゴリー：</h3>
      <select v-model="categoryId">
        <option
          v-for="item of chatCategoryItems"
          :value="item.id"
          :key="item.id"
        >
          {{ item.label }}
        </option>
      </select>
    </div>
    <div class="keyword">
      <input
        v-model.trim="keyword"
        type="text"
        placeholder="キーワードを入力"
        class="type_keyword"
      />
    </div>
  </div>
  <div class="period">
    <div class="period_date">
      <h3>投稿期間：</h3>
      <div class="form_group">
        <DatePicker
          id="postingDateFrom"
          :value="dateFrom"
          @setValid="setValid"
        />
        <span class="separate">ー</span>
        <DatePicker id="postingDateTo" :value="dateTo" @setValid="setValid2" />
      </div>
      <template v-if="!isSPMode">
        <h3>返信期間：</h3>
        <div class="form_group">
          <DatePicker
            id="replyDateFrom"
            :value="dateFrom2"
            @setValid="setValid3"
          />
          <span class="separate">ー</span>
          <DatePicker id="replyDateTo" :value="dateTo2" @setValid="setValid4" />
        </div>
      </template>
    </div>
    <template v-if="isSPMode">
      <div class="period_date">
        <h3>返信期間：</h3>
        <div class="form_group">
          <DatePicker
            id="replyDateFrom"
            :value="dateFrom2"
            @setValid="setValid3"
          />
          <span class="separate">ー</span>
          <DatePicker id="replyDateTo" :value="dateTo2" @setValid="setValid4" />
        </div>
      </div>
    </template>
  </div>
  <div class="metaData">
    <div class="metaData_group">
      <div class="good">
        <h3>イイね：</h3>
        <select v-model="likeNumber">
          <option
            v-for="item of goodCountRangeItems"
            :value="item.value"
            :key="item.label"
          >
            {{ item.label }}
          </option>
        </select>
      </div>
      <div class="replyCount">
        <h3>返信数：</h3>
        <select v-model="replyNumber">
          <option
            v-for="item of replyCountRangeItems"
            :value="item.value"
            :key="item.label"
          >
            {{ item.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="search">
      <button class="btn" @click="search" :disabled="dateInvalid">検索</button>
    </div>
  </div>
</template>

<script>
import search from '../mixin/search';
import DatePicker from './DatePicker';
export default {
  name: 'SearchFrameChat',
  mixins: [search],
  components: { DatePicker },

  data() {
    return {
      dateFrom: this.getFromDate(),
      dateTo: this.fmtymd(),
      dateFrom2: null,
      dateTo2: null,
      unreadFlag: false,
      readFlag: false,
      categoryId: 0,
      likeNumber: '',
      replyNumber: '',
      gaEventName: 'chat_search',
    };
  },
  methods: {
    createParam() {
      let dtlist = [
        'postingDateFrom',
        'postingDateTo',
        'replyDateFrom',
        'replyDateTo',
      ];
      let params = this.getParams(dtlist);
      params.keyword = this.toHalfSpace(this.keyword);
      if (this.readFlag == this.unreadFlag) {
        delete params.readFlag;
      } else {
        if (this.unreadFlag) params.readFlag = 0;
        if (this.readFlag) params.readFlag = 1;
      }
      if (this.categoryId == '0') {
        params.categoryId = '';
      } else {
        params.categoryId = this.categoryId;
      }
      params.likeNumber = this.likeNumber;
      params.replyNumber = this.replyNumber;
      return params;
    },
    recoverParam(prm) {
      if (prm) {
        this.keyword = prm.keyword;
        this.dateFrom = prm.postingDateFrom;
        this.dateTo = prm.postingDateTo;
        this.dateFrom2 = prm.replyDateFrom;
        this.dateTo2 = prm.replyDateTo;
        this.unreadFlag = prm.unreadFlag;
        this.readFlag = prm.readFlag;
        this.categoryId = prm.categoryId || 0;
        this.likeNumber = prm.likeNumber;
        this.replyNumber = prm.replyNumber;
      }
    },
  },
};
</script>
