<template>
  <nav>
    <ul class="tabNav">
      <li :class="isCurrentTab('全て')" @click="changeTab('全て')">
        <a href="#tab_01" id="btn_tab_01">全て</a>
      </li>
      <li :class="isCurrentTab('全社共通')" @click="changeTab('全社共通')">
        <a href="#tab_02" id="btn_tab_02">全社共通</a>
      </li>
      <li
        :class="isCurrentTab('営業')"
        @click="changeTab('営業')"
        id="btn_tab_03"
      >
        <a href="#tab_03">営業</a>
      </li>
      <li :class="isCurrentTab('NAM')" @click="changeTab('NAM')">
        <a href="#tab_04" id="btn_tab_04">NAM</a>
      </li>
    </ul>
  </nav>
  <div class="tabcontent loadData">
    <div class="loader"><span></span></div>
    <div id="tab_01" class="tabBox">
      <ul>
        <li v-for="item of infoItmesAll" :key="item.information_id">
          <router-link
            :to="`/detail_news?id=${item.information_id}`"
            class="container"
            @click="pushGA(item)"
          >
            <span
              class="asideIcon important"
              :class="importanceFlag(item.importance_id) ? 'high' : ''"
            ></span>
            <span class="date">{{ fmtymdS(item.publish_start_date) }}</span>
            <strong :style="titleStyle(item)">{{ item.title }}</strong>
            <span class="news_category">{{ item.category }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!--#tab_01-->
    <div id="tab_02" class="tabBox">
      <ul>
        <li v-for="item of infoItmes('全社共通')" :key="item.information_id">
          <router-link
            :to="`/detail_news?id=${item.information_id}`"
            class="container"
            @click="pushGA(item)"
          >
            <span
              class="asideIcon important"
              :class="importanceFlag(item.importance_id) ? 'high' : ''"
            ></span>
            <span class="date">{{ fmtymdS(item.publish_start_date) }}</span>
            <strong :style="titleStyle(item)">{{ item.title }}</strong>
            <span class="news_category">{{ item.category }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!--#tab_02-->
    <div id="tab_03" class="tabBox">
      <ul>
        <li
          v-for="item of infoItmes('営業')"
          :key="item.information_id"
          class="container"
        >
          <router-link
            :to="`/detail_news?id=${item.information_id}`"
            @click="pushGA(item)"
          >
            <span
              class="asideIcon important"
              :class="importanceFlag(item.importance_id) ? 'high' : ''"
            ></span>
            <span class="date">{{ fmtymdS(item.publish_start_date) }}</span>
            <strong :style="titleStyle(item)">{{ item.title }}</strong>
            <span class="news_category">{{ item.category }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!--#tab_03-->
    <div id="tab_04" class="tabBox">
      <ul>
        <li
          v-for="item of infoItmes('NAM')"
          :key="item.information_id"
          class="container"
        >
          <router-link
            :to="`/detail_news?id=${item.information_id}`"
            @click="pushGA(item)"
          >
            <span
              class="asideIcon important"
              :class="importanceFlag(item.importance_id) ? 'high' : ''"
            ></span>
            <span class="date">{{ fmtymdS(item.publish_start_date) }}</span>
            <strong :style="titleStyle(item)">{{ item.title }}</strong>
            <span class="news_category">{{ item.category }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import dataset from '../mixin/dataset';
import api from '../services/api';
export default {
  mixins: [dataset],
  data: function () {
    return {
      currentTab: '',
    };
  },
  created() {
    this.setList();
  },
  computed: {
    infoItmes: function () {
      let self = this;
      return function (kind) {
        return self.items ? self.items.filter((x) => x.category == kind) : [];
      };
    },
    infoItmesAll: function () {
      let self = this;
      return self.items
        ? self.items.filter((x) => x.category != '社長ノート')
        : [];
    },
    titleStyle: function () {
      return function (item) {
        // cssでの指定だと別の指定に上書きされてしまうので、styleで直接指定
        const bold = {
          'font-weight': 'bold',
        };
        return item.read_flag ? '' : bold;
      };
    },
    isCurrentTab: function () {
      return function (value) {
        return this.currentTab == value ? 'active' : '';
      };
    },
  },
  mounted() {
    const topInformationTab = this.getCookie('topInformationTab');
    this.currentTab = topInformationTab ? topInformationTab : '全て';
    this.showTabNav(false);

    // お知らせタブのクリックイベントを起こす
    const tabid =
      this.currentTab == '全て'
        ? 'btn_tab_01'
        : this.currentTab == '全社共通'
        ? 'btn_tab_02'
        : this.currentTab == '営業'
        ? 'btn_tab_03'
        : 'btn_tab_04';
    const btn = document.getElementById(tabid);
    btn.click();
  },
  methods: {
    async getData(params) {
      params.pageLimit = 9999;
      params.orders = '-publish_start_date';
      params.postingDateFrom = this.getFromDate().replaceAll('/', '-');
      params.postingDateTo = this.fmtymd().replaceAll('/', '-');
      return await api.getInformationList(params);
    },
    pushGA(item) {
      this.pushGATopPage('お知らせ', item.title);
    },
    changeTab(value) {
      this.currentTab = value;
      this.setCookie({
        topInformationTab: value,
        'max-age': 60 * 60 * 24 * 30,
        secure: true,
      });
    },
  },
};
</script>

<style scoped></style>
