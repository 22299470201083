import moment from 'moment';
import api from '../services/api';
import file from '../services/file';

let dateRange = process.env.VUE_APP_INIT_DATE_RANGE;
const chatFileUrl = process.env.VUE_APP_CHATFILE_URL;
const infoFileUrl = process.env.VUE_APP_INFOFILE_URL;
let toast = null;

import {
  loading,
  loaded,
  modalShowing,
  openModal,
  closeModal,
  isSPMode,
  getFiles,
  clearFileSelect,
  setupDatePicker,
  setupSPMenu,
  removeItem,
  showConfirmDialog,
  setConfirm,
  closeNavi,
  showTabNav,
  owlCarousel,
  bindModalButton,
  setDateClear,
  toKB,
  round,
} from '../assets/js/extend';

export default {
  data() {
    return {
      isDev: process.env.NODE_ENV == 'development',
      files: [],
      invDates: [false, false, false, false, false],
    };
  },
  computed: {
    toKB() {
      return function (byte) {
        return toKB(byte);
      };
    },
    isSPMode() {
      return isSPMode();
    },
    href: function () {
      return function (p) {
        return p.select_pagelink == 'ページ'
          ? `/detail_page?id=${p.id}`
          : p.url;
      };
    },
    watchType: function () {
      return function (kw) {
        return kw == '' ? 'お知らせウォッチ' : kw;
      };
    },
    position() {
      return function (pos) {
        switch (Number(pos)) {
          case 0:
            return 'top';
          case 1:
            return 'bottom';
          case 2:
            return 'left';
          case 3:
            return 'right';
          default:
            return 'top';
        }
      };
    },
    yulifeUrl() {
      return process.env.VUE_APP_YULIFE_URL;
    },
    hapiwakuUrl() {
      return process.env.VUE_APP_HAPIWAKU_URL;
    },
    dateInvalid() {
      return (
        this.invDates[0] ||
        this.invDates[1] ||
        this.invDates[2] ||
        this.invDates[3] ||
        this.invDates[4]
      );
    },
  },
  // props: { },
  // watch: { },
  // beforeMount() { },
  mounted() {
    toast = this.$toast;

    //ページ先頭へ移動
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
    this.files = [];
  },
  methods: {
    gtag() {
      window.dataLayer.push(arguments);
    },
    gaEvent(evnt, parm) {
      this.gtag('event', evnt, parm);
    },
    setGtagOption(userData) {
      let option = {
        //ユーザー情報をGA送信に含める
        organization_code: userData.orgCode,
        organization_name: userData.orgName,
        grade: userData.grade,
        office: userData.office,
        rank: userData.rank,
      };
      if (this.isDev) option.debug_mode = true;
      this.gtag('config', process.env.VUE_APP_GA_ID, option);
    },
    pushGATopPage(category, title) {
      this.gaEvent('top_click', {
        category: category,
        title: title,
      });
    },
    toHalfSpace(s) {
      return s.replace(/[\u{20}\u{3000}]/u, ' ');
    },
    round(value, base) {
      round(value, base);
    },
    async confirm(msg) {
      if (!msg) msg = '削除してもよろしいですか？';
      return await new Promise((res) => {
        showConfirmDialog(
          msg,
          () => res(true),
          () => res(false)
        );
      });
    },
    async showInfo(msg) {
      return await new Promise((res) => {
        showConfirmDialog(
          msg,
          () => res(false),
          () => res(false),
          true
        );
      });
    },
    setConfirm(cls, func) {
      setConfirm(cls, func);
    },
    bindModalButton() {
      bindModalButton();
    },
    setDateClear() {
      setDateClear();
    },
    getFileUrl(file) {
      return chatFileUrl + file;
    },
    getInfoFileUrl(file) {
      return infoFileUrl + file;
    },
    isEven(num) {
      return (
        (typeof num === 'number' || num instanceof Number) && num % 2 === 0
      );
    },
    loaded() {
      loaded();
    },
    loading() {
      loading();
    },
    openModal(id) {
      openModal(id);
    },
    closeModal() {
      closeModal();
    },
    showTabNav(isActiveFirstItem = true) {
      showTabNav(isActiveFirstItem);
    },
    owlCarousel(loop) {
      owlCarousel(loop);
    },
    getFiles() {
      return getFiles() || [];
    },
    clearFileSelect() {
      clearFileSelect();
    },
    setupSPMenu() {
      setupSPMenu();
    },
    setupDatePicker(id) {
      setupDatePicker(id);
    },
    removeItem(clsName) {
      removeItem(clsName);
    },
    async downloadFile(kind, path, filename, title) {
      let params = {
        kind: kind,
        dir: path,
      };
      if (filename) params.file = filename;
      let ret = await api.getSignedUrl(params);
      if (!ret.url) return;

      let fn = filename;
      if (!fn) {
        fn = `${title}_${this.fmtymdShort()}.zip`;
        //"https://noritz-p...ws.com/XXXXXX/XX.jpg?X-Am... 署名付きURLからファイル名を取得する
        let m = ret.url.match(/^https:\/\/.*(\w+\.\w+)\?/);
        if (m && m.length > 0) {
          let f = m[0].slice(0, -1);
          if (!f.endsWith('.zip')) {
            //Zipファイルでないとき
            fn = decodeURI(f.split('/').pop());
          }
        }
      }
      file.downloadFile(ret.url, fn);
      if (title) {
        this.gaEvent('file_download', { file_name: fn, title: title });
      }
    },
    async uploadFiles(kind, path, id, delFiles) {
      let promises = [];
      let files = this.getFiles();

      //レコード削除
      let paramDel = { deleteFlag: 'true' };
      if (kind == 'info') {
        paramDel.informationId = id;
      } else {
        paramDel.contentsType = kind;
        paramDel.contentsId = id;
        paramDel.filePath = path;
      }
      for (let file of delFiles) {
        paramDel.fileName = file.file_name;
        if (kind == 'info') {
          paramDel.filePath = path + (file.file_div == '1' ? '|view' : '');
        }
        paramDel.loading = true; //ローディング表示を自動で消さない
        let p =
          kind == 'info'
            ? api.registerInformationFile(paramDel)
            : api.registerChatFile(paramDel);
        promises.push(p);
      }
      //ここまで先に処理を行う
      await Promise.all(promises);

      promises = [];
      let param = {};
      if (kind == 'info') {
        param.informationId = id;
      } else {
        param.contentsType = kind;
        param.contentsId = id;
      }
      for (let file of files) {
        //ファイルアップロード
        let pathWk = path + (file.file_div == '1' ? '|view' : '');
        let upld = this.uploadFile(kind, pathWk, file);
        promises.push(upld);

        //レコード登録
        param.fileName = file.name;
        param.fileSize = file.size;
        param.imageFlag = this.isImageFile(file.name);
        param.filePath = pathWk;
        if (kind == 'info') {
          param.fileDiv = file.file_div || 0; //0:添付ファイル、1:画像挿入ファイル
        }
        param.loading = true; //ローディング表示を自動で消さない
        let p =
          kind == 'info'
            ? api.registerInformationFile(param)
            : api.registerChatFile(param);
        promises.push(p);
      }
      await Promise.all(promises);
    },
    async uploadFile(kind, path, fl) {
      let params = {
        kind: kind == 'chtrp' ? 'cht' : kind,
        dir: path,
        put: true,
        file: fl.name,
        loading: true, //ローディング表示を自動で消さない
      };
      //署名付きURL取得
      let ret = await api.getSignedUrl(params);
      //アップロード
      return await file.upload(ret.url, fl);
    },
    getDateValue(id) {
      let elm = document.getElementById(id);
      if (elm && !this.isEmpty(elm.value))
        return elm.value.replaceAll('/', '-');
      return '';
    },
    //乱数
    getRandom: (max) => {
      return Math.floor(Math.random() * (max + 1));
    },
    // ディープコピー
    deepCopy: function (o) {
      return JSON.parse(JSON.stringify(o));
    },
    isEmpty: (arg) => {
      if (arg == null) return true;
      if (arg === void 0) return true;
      switch (typeof arg) {
        case 'object':
          if (Array.isArray(arg)) {
            // When object is array:
            return arg.length === 0;
          }
          // When object is not array:
          if (
            Object.keys(arg).length > 0 ||
            Object.getOwnPropertySymbols(arg).length > 0
          ) {
            return false;
          }
          if (arg.valueOf().length !== undefined) {
            return arg.valueOf().length === 0;
          }
          if (typeof arg.valueOf() !== 'object') {
            return this.isEmpty(arg.valueOf());
          }
          return true;
        default:
          break;
      }
      let tmp = '' + arg;
      return 0 === tmp.length;
    },
    isImageFile(file) {
      return this.isTargetFile(file, [
        'jpg',
        'jpeg',
        'gif',
        'tiff',
        'webp',
        'svg',
        'png',
      ]);
    },
    isTextFile(file) {
      return this.isTargetFile(file, ['txt']);
    },
    isTargetFile(file, exts) {
      let ext = file.split('.').pop().toLowerCase();
      return exts.includes(ext);
    },
    outlog(msg) {
      console.log(this.getLogTime() + msg);
    },
    modalShowing() {
      return modalShowing();
    },
    getLogTime() {
      return new Date().toLocaleString() + ':';
    },
    compareDateTime(x, y) {
      //引数１が引数２より大きい場合true
      return this.fmtymdhmS(x) > this.fmtymdhmS(y);
    },
    fmtymdS(ymd) {
      return ymd ? ymd.substr(0, 10).replaceAll('-', '/') : '';
    },
    fmtymdhmsS(ymd) {
      return ymd
        ? ymd.substr(0, 19).replaceAll('-', '/').replace('T', ' ')
        : '';
    },
    fmtymdhmS(ymd) {
      return ymd
        ? ymd.substr(0, 16).replaceAll('-', '/').replace('T', ' ')
        : '';
    },
    fmtymdShort(mmt = moment()) {
      return mmt.format('YYYYMMDD');
    },
    fmtymd(mmt = moment()) {
      return mmt.format('YYYY/MM/DD');
    },
    fmtymdAdd(days) {
      return this.fmtymd(moment().add(days, 'd'));
    },
    getFromDate() {
      return this.fmtymdAdd(-1 * dateRange);
    },
    fmtDateTime(mmt = moment()) {
      return mmt.format('YYYY/MM/DD HH:mm:ss');
    },
    isValidDate(date) {
      return moment(date.replaceAll('/', '-')).isValid();
    },
    clickLink(category, item) {
      this.pushGATopPage(category, item.title);
      this.movePage(item);
    },
    movePage(p) {
      if (p.select_pagelink == 'ページ') {
        this.$router.push(`/detail_page?id=${p.id}`);
      } else {
        window.open(p.url, '_blank');
      }
    },
    closeNavi() {
      closeNavi();
    },
    showPopError(msg) {
      (this.$toast || toast).error(msg, { position: 'top', maxToasts: 1 });
    },
    showPopInfo(msg) {
      (this.$toast || toast).info(msg, { position: 'top', maxToasts: 1 });
    },
    fmtId(id) {
      if (this.isEmpty(id)) return id;
      return '#' + ('000' + id).slice(-3);
    },
    checkDigit(str) {
      str = ('00000' + str).slice(-13);
      let evenNum = 0,
        oddNum = 0;
      for (var i = 0; i < str.length - 1; i++) {
        if (i % 2 == 0) {
          // 「奇数」かどうか（0から始まるため、iの偶数と奇数が逆）
          oddNum += parseInt(str[i]);
        } else {
          evenNum += parseInt(str[i]);
        }
      }
      let n = 10 - parseInt((evenNum * 3 + oddNum).toString().slice(-1));
      return (n == 10 ? 0 : n) === parseInt(str.slice(-1));
    },
    unescapeHtml(target) {
      if (typeof target !== 'string') return target;
      var patterns = {
        '&lt;': '<',
        '&gt;': '>',
        '&amp;': '&',
        '&quot;': '"',
        '&#x27;': "'",
        '&#x60;': '`',
      };
      return target.replace(/&(lt|gt|amp|quot|#x27|#x60);/g, function (match) {
        return patterns[match];
      });
    },
    isDiff(obj1, obj2) {
      let x = JSON.stringify(obj1);
      let y = JSON.stringify(obj2);
      return x != y;
    },
    setCookie(cookie) {
      let string = '';
      for (const key of Object.keys(cookie)) {
        string += `${key}=${encodeURIComponent(cookie[key])};`;
      }

      if (string) {
        document.cookie = string;
      }
    },
    getCookie(key) {
      let result = undefined;
      let r = document.cookie.split(';'); //split(';')を使用しデータを1つずつに分ける

      r.forEach(function (value) {
        let content = value.split('='); //split('=')を使用しcookie名と値に分ける

        if (content[0].trim() == key) {
          result = decodeURIComponent(content[1]);
        }
      });
      return result;
    },
    setValid(inv) {
      this.invDates[0] = inv;
    },
    setValid2(inv) {
      this.invDates[1] = inv;
    },
    setValid3(inv) {
      this.invDates[2] = inv;
    },
    setValid4(inv) {
      this.invDates[3] = inv;
    },
    setValid5(inv) {
      this.invDates[4] = inv;
    },
  },
};
export function validPass(value) {
  const uppercase = /[A-Z]/.test(value);
  const lowercase = /[a-z]/.test(value);
  const number = /[0-9]/.test(value);
  // const special = /[#?!@$%^&*-]/.test(value);
  return uppercase && lowercase && number;
}
