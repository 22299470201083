<template>
  <ThredFrame kind="page">
    <div v-if="noAuth" class="pageDetail_contents">
      <h2 class="noAuth">閲覧権限がありません</h2>
    </div>
    <div v-if="!noAuth" class="pageDetail_title">
      <h2>{{ itemData.title }}</h2>
    </div>
    <div v-if="!noAuth" class="pageDetail_contents">
      <div class="main_contents">
        <div class="txt_contents" v-html="$sanitize(body)"></div>
      </div>
    </div>
  </ThredFrame>
</template>
<script>
import view from '../mixin/view';
import api from '../services/api';
import dataset from '../mixin/dataset';
import thread from '../mixin/thread';
import ThredFrame from '../components/ThredFrame.vue';
export default {
  name: 'Page',
  components: { ThredFrame },
  mixins: [view, dataset, thread],
  // data() {
  // 	return {};
  // },
  computed: {
    itemData() {
      return this.items.length > 0 ? this.items[0] : {};
    },
    body() {
      if (!this.itemData.page_body) return '';
      let body = this.itemData.page_body.reduce((acc, item) => {
        // acc += item.replaceAll('\\"', '"');
        if (item.rich_editor) acc += item.rich_editor;
        if (item.html) acc += item.html;
        return acc;
      }, '');
      return this.unescapeHtml(body);
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.setList({ contentsId: to.query.id });
    next();
  },
  methods: {
    setItems() {
      let id = this.$route.query.id;
      this.setList({ contentsId: id });
    },
    async getData(params) {
      return await api.getPageDetail(params);
    },
  },
};
</script>
