<template>
  <ListFrame
    ref="list"
    title="チャット"
    :totalCount="totalCount"
    :action="setList"
    :showLimit="false"
    class="list_result chat"
  >
    <div class="result_contents watch_chat">
      <div
        class="result_contents_item"
        :class="`${item.read_flag ? 'unread' : ''} bg_${categoryIcon(
          item.category_id
        )}`"
        v-for="item of items"
        :key="item.id"
      >
        <router-link :to="`/detail_chat?id=${item.chat_id}`">
          <div class="keyword">
            <h3>{{ item.watch_keyword }}</h3>
          </div>
          <div class="postGroup">
            <div class="mainPost">
              <div class="category">
                <p>
                  <span
                    class="chatIcon"
                    :class="categoryIcon(item.category_id)"
                  ></span>
                </p>
              </div>
              <div class="sp_wrap">
                <div class="author">
                  <h3>
                    <span>{{ item.chat_organization_name }}</span>
                    <strong>{{ item.chat_user_name }}</strong>
                  </h3>
                </div>
                <div class="date">
                  <p>
                    投稿：{{ fmtymdS(item.create_datetime) }}<br />(更新：{{
                      fmtymdS(item.update_datetime)
                    }})
                  </p>
                </div>
                <div class="to">
                  <h4 class="destination">
                    <span>TO</span>
                    <strong>{{ item.address }}</strong>
                  </h4>
                </div>
                <div class="description container">
                  <h3 class="ellipsis">{{ item.body }}</h3>
                </div>
                <div class="attached">
                  <p><span class="icon attached">添付ファイル有</span></p>
                </div>
                <div class="good">
                  <p class="asideIcon good type_gray">{{ item.like_number }}</p>
                </div>
                <div class="comment">
                  <p class="asideIcon comment">{{ item.rep_number }}</p>
                </div>
              </div>
            </div>
            <div class="replyPost" v-if="item.chat_reply_id">
              <div class="category">
                <!--★XDにはなかったんですが、もしこの返信アイコンの代わりにベストアンサーのメダルが必要なら「.best」を追加で付与してください-->
                <p class="replyCategory">返信</p>
              </div>
              <div class="sp_wrap">
                <div class="author">
                  <h3>
                    <span>{{ item.reply_organization_name }}</span>
                    <strong>{{ item.reply_user_name }}</strong>
                  </h3>
                </div>
                <div class="date">
                  <p>
                    投稿：{{ fmtymdS(item.create_datetime_r) }}<br />(更新：{{
                      fmtymdS(item.update_datetime_r)
                    }})
                  </p>
                </div>
                <div class="to"></div>
                <div class="description container">
                  <h3 class="ellipsis">
                    {{ item.comment }}
                  </h3>
                </div>
                <div class="attached">
                  <p>
                    <span
                      class="icon"
                      :class="item.file_flag ? 'attached' : ''"
                    />
                  </p>
                </div>
                <div class="good">
                  <p class="asideIcon good type_gray">
                    {{ item.like_number_r }}
                  </p>
                </div>
                <div class="comment"></div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </ListFrame>
  <ChatDialog :action="conditionSearch" />
</template>
<script>
import dataset from '../../mixin/dataset';
import api from '../../services/api';
import ListFrame from '../ListFrame.vue';
export default {
  mixins: [dataset],
  components: { ListFrame },
  created() {
    this.setList({ contentsType: this.contentsType.chat }, true);
  },
  methods: {
    async getData(params) {
      return await api.getWatchList(params);
    },
  },
};
</script>
