<template>
  <section class="list_result detail_page">
    <div style="text-align: center">
      <span>
        <h2>{{ msg1 }}</h2>
      </span>
    </div>
    <div class="loadData ld">
      <div class="loader"><span></span></div>
    </div>
    <div class="pageDetail_contents" style="text-align: center">
      <div class="main_contents">
        <strong>{{ msg2 }}</strong>
      </div>
    </div>
  </section>
</template>
<script>
import store from '../store/index';
import api from '../services/api';
import { loaded } from '../assets/js/extend';

export default {
  props: {
    div: { default: 'notFound' },
  },
  data() {
    return {
      msg1: '',
      msg2: '',
    };
  },
  async mounted() {
    this.msg1 = 'ログイン中です';
    this.msg2 = 'しばらくお待ちください';
    if (store.state.hashValue) {
      if (!(await this.login())) return;
      //ログイン成功（->トップまたはリダイレクト先）
      let path = this.getRedirectPath();
      this.$router.push(path);
    } else {
      //認証
      const loginUrl = process.env.VUE_APP_LOGIN_URL;
      window.location = loginUrl;
    }
  },
  methods: {
    getRedirectPath() {
      let url = store.getters.getTempParams();
      if (url && url.lastIndexOf('/') > -1) {
        return url.substr(url.lastIndexOf('/'));
      }
      return '/';
    },
    async login() {
      let hash = store.state.hashValue;
      const ret = await api.login(hash);
      if (!ret.response || ret.response.statusCode != 200) {
        this.msg1 = 'ログインに失敗しました';
        this.msg2 = '';
        return false;
      }
      // 署名付きcookieの登録
      const cookies = ret.response.body.cookies['Set-Cookie'];
      for (let cookie of cookies) {
        document.cookie = decodeURI(cookie);
      }
      // ユーザ情報をセット
      this.$root.setUserInfo(ret.response.body.userInfo);

      this.loading();
      let waits = [];

      //マスター情報の取得
      waits.push(api.getMasterList({ loading: true }));
      //メニュー情報の取得
      waits.push(api.getMenu({ loading: true }));

      let rets = await Promise.all(waits);
      if (rets[0].statusCode == 200) this.$root.masterInfo = rets[0];
      if (rets[1].statusCode == 200) this.$root.menuInfo = rets[1];
      loaded();
      return true;
    },
  },
};
</script>
<style scoped>
h2 {
  padding: 20px;
}
strong {
  color: pink;
  vertical-align: middle;
  font-size: 14px;
}
</style>
