<template>
  <header>
    <h2>
      システム連絡<span>{{ `（全${items.length}件）` }}</span>
    </h2>
  </header>
  <div class="top_systemList">
    <div v-for="item of items" :key="item.id" class="top_systemList_item">
      <router-link
        :to="`/detail_system?id=${item.id}`"
        @click="
          gaEvent('top_click', {
            category: 'システム連絡',
            title: item.title,
          })
        "
      >
        <dl>
          <dt>
            <span
              v-for="category of item.category"
              :key="category"
              class="top tag_system"
              :class="sysCategoryClass(category)"
              >{{ category }}</span
            >
          </dt>
          <dd>
            <span>{{ fmtymdS(item.publish_start_date) }}</span>
            <strong>{{ item.title }}</strong>
          </dd>
        </dl>
      </router-link>
    </div>
  </div>
</template>

<script>
import dataset from '../mixin/dataset';
import api from '../services/api';
export default {
  name: 'SystemInfo',
  mixins: [dataset],
  created() {
    this.setList();
  },
  methods: {
    async getData(params) {
      return await api.getSystemList(params);
    },
  },
};
</script>
