<template>
  <h2 v-if="!isSPMode">{{ title }}一覧</h2>
  <div class="period_date">
    <h3>期間：</h3>
    <div class="form_group group_margin">
      <DatePicker id="dateFrom" :value="dateFrom" @setValid="setValid" />
      <span class="separate">ー</span>
      <DatePicker id="dateTo" :value="dateTo" @setValid="setValid2" />
    </div>
  </div>
  <div class="search">
    <div class="form_group">
      <input
        v-model.trim="keyword"
        type="text"
        placeholder="キーワードを入力"
        class="type_keyword"
      />
      <button class="btn" @click="search" :disabled="dateInvalid">検索</button>
    </div>
  </div>
</template>

<script>
import search from '../mixin/search';
import DatePicker from './DatePicker.vue';
export default {
  name: 'SearchFrameTopics',
  mixins: [search],
  components: { DatePicker },
  data() {
    return {
      gaEventName: 'topics_search',
      dateFrom: this.getFromDate(),
      dateTo: this.fmtymd(),
      // searchParams: {},
    };
  },
  methods: {
    createParam() {
      let dtlist = ['dateFrom', 'dateTo'];
      let params = this.getParams(dtlist);
      params.keyword = this.toHalfSpace(this.keyword);
      params.orders = '-publish_start_date';
      return params;
    },
  },
};
</script>
