<template>
  <ul class="owl-carousel">
    <li v-for="(item, idx) of items" :key="item.id">
      <a :href="item.url" target="_blank" class="link_item" :data-idx="idx">
        <figure>
          <template v-if="item.image && item.image.url">
            <img :src="item.image.url" class="img_responsive" />
          </template>
          <template v-else>
            <img
              src="@/assets/img/topics/i_noImage.png"
              class="img_responsive"
            />
          </template>
        </figure>
        <h3 class="container">
          <dd>{{ item.title }}</dd>
          <!-- <span>{{ item.title }}</span> -->
        </h3>
        <div class="group container">
          <dd>{{ item.department_name }}</dd>
          <dd v-show="!item.department_name">&nbsp;</dd>
        </div>
        <dl class="container">
          <dt>{{ fmtymdS(item.publish_start_date) }}</dt>
          <dd>{{ item.overview }}</dd>
        </dl>
        <nav>
          <p>もっと見る</p>
        </nav>
      </a>
    </li>
  </ul>
</template>

<script>
import dataset from '../mixin/dataset';
import api from '../services/api';
export default {
  name: 'Topics',
  mixins: [dataset],
  created() {
    this.setList();
  },
  watch: {
    items() {
      this.owlCarousel(this.items.length > 2);
      setTimeout(this.bindClick, 1000);
    },
  },
  methods: {
    bindClick() {
      let self = this;
      var linkitems = document.querySelectorAll('.link_item[data-idx]');
      linkitems.forEach(function (target) {
        target.addEventListener('click', function () {
          self.pushGA(self.items[target.dataset.idx]);
        });
      });
    },
    async getData(params) {
      params.pageLimit = 6;
      params.dateTo = this.fmtymd().replaceAll('/', '-');
      params.orders = '-publish_start_date';
      return await api.getTopics(params);
    },
    pushGA(item) {
      this.pushGATopPage('トピックス', item.title);
      this.gaEvent('topics_detail', {
        title: item.title,
        group: item.department_name,
        date: item.publish_start_date,
      });
    },
  },
};
</script>
<style scoped>
img.img_responsive {
  height: 137px;
}
</style>
