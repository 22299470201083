<template>
  <div class="loadData">
    <div class="loader"><span></span></div>
    <ol>
      <li v-for="item of itemsTop5" :key="item.information_id">
        <router-link
          :to="`/detail_news?id=${item.information_id}`"
          @click="
            gaEvent('top_click', {
              category: 'お知らせランキング',
              title: item.title,
            })
          "
        >
          {{ item.title }}
        </router-link>
      </li>
    </ol>
  </div>
</template>

<script>
import dataset from '../mixin/dataset';
import api from '../services/api';
export default {
  mixins: [dataset],
  created() {
    this.setList();
  },
  computed: {
    itemsTop5() {
      return this.items.slice(0, 5);
    },
  },
  methods: {
    async getData(params) {
      return await api.getInformationRanking(params);
    },
  },
};
</script>
