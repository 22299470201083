import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export const state = {
  //ハッシュ値
  hashValue: null,
  //トークンの有効期間
  expires: null,
  //IDリスト（前後の詳細画面への遷移に使用）
  idList: [],
  //検索条件（詳細画面から一覧へのもどる際に使用／キー：画面ID）
  saveParams: [],
  //画面間パラメータ（その他画面間で連携するデータ）
  tempParams: null,
};
const options = {
  storage: window.sessionStorage,
};

export default new Vuex.Store({
  state: state,
  plugins: [createPersistedState(options)],
  getters: {
    getSaveParams: (state) => (path) => {
      let item = state.saveParams.find((x) => x.path === path);
      state.saveParams = state.saveParams.filter((x) => x.path != path);
      return item ? item.params : null;
    },
    getTempParams: (state) => () => {
      let item = state.tempParams;
      state.tempParams = null;
      return item;
    },
  },
  mutations: {
    setIdList(state, item) {
      state.idList = item;
    },
    setHashValue(state, info) {
      state.hashValue = info;
    },
    setExpires(state, info) {
      state.expires = info;
    },
    setTempParams(state, item) {
      state.tempParams = item;
    },
  },
  actions: {
    setSaveParams(ctx, payload) {
      let idx = ctx.state.saveParams.findIndex((x) => x.path === payload.path);
      if (idx > -1) ctx.state.saveParams[idx].params = payload.params;
      else ctx.state.saveParams.push(payload);
    },
  },
});
