<template>
  <div class="modal" id="modal-chat">
    <div class="overLay modalClose confirm chat"></div>
    <div class="innerModal loadData ld">
      <span class="btn modalClose outer confirm chat"></span>
      <div class="innerModal_header common_header">
        <h2 v-show="!postData.chat_id">チャット新規投稿</h2>
        <h2 v-show="postData.chat_id">チャット編集</h2>
      </div>
      <div class="innerModal_body chat_edit chat">
        <header>
          <div class="category">
            <h3>カテゴリー：</h3>
            <select v-model="vld.postData.category_id.$model">
              <option
                v-for="item of chatCategoryItems"
                :value="item.id"
                :key="item.label"
              >
                {{ item.label }}
              </option>
            </select>
          </div>
          <div class="to">
            <h3>宛名：</h3>
            <input
              v-model="vld.postData.address.$model"
              type="text"
              placeholder="入力してください"
            />
          </div>
        </header>
        <div class="body">
          <h3>本文：</h3>
          <textarea
            v-model.trim="vld.postData.body.$model"
            placeholder="入力してください"
          ></textarea>
        </div>
        <div class="file_contents file_input">
          <template v-for="(file, idx) of postData.filesChat" :key="idx">
            <ChatImage
              :file="file"
              :showDel="true"
              @delete="file.deleted = true"
            />
          </template>
          <div class="fileClip_wrap">
            <label class="fileClip_label">
              <input type="file" multiple class="fileClip_input" />
            </label>
          </div>
        </div>
      </div>
      <div class="innerModal_footer">
        <nav class="modal_ctrl">
          <div class="note alert">
            <p>
              ※添付ファイルを含む投稿内容は、全てのサイト利用者が閲覧できます。
            </p>
          </div>
          <div class="ctrlArea">
            <ul>
              <li>
                <button class="btn type_cancel modalClose confirm chat">
                  キャンセル
                </button>
              </li>
              <li>
                <button
                  v-show="!postData.chat_id"
                  class="btn type_addition"
                  @click.prevent="post"
                  :disabled="vld.$invalid"
                >
                  投稿
                </button>
                <button
                  v-show="postData.chat_id"
                  class="btn type_save"
                  @click.prevent="post"
                  :disabled="vld.$invalid"
                >
                  保存
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../services/api';
import useVuelidate from '@vuelidate/core';
import { required, minValue, maxLength } from '@vuelidate/validators';
import ChatImage from '../components/ChatImage.vue';
export default {
  components: { ChatImage },
  props: {
    action: { default: () => {} },
  },
  setup() {
    return { vld: useVuelidate() };
  },
  computed: {
    vlData() {
      return this.vld.postData;
    },
  },
  data() {
    return {
      orgData: {},
      postData: {
        category_id: '0',
        body: '',
      },
    };
  },
  validations() {
    return {
      postData: {
        category_id: { minValue: minValue(1) },
        address: { required, maxLength: maxLength(50) },
        body: { required, maxLength: maxLength(1000) },
      },
    };
  },
  mounted() {
    //キャンセル操作のクラスと紐づける
    this.setConfirm('chat', this.cancel);
  },
  methods: {
    async cancel() {
      let ret = true;
      if (
        this.vlData.category_id.$dirty ||
        this.vlData.address.$dirty ||
        this.vlData.body.$dirty ||
        this.checkModFiles()
      ) {
        ret = await this.confirm('編集内容を取り消してもよろしいですか？');
      }
      if (ret) this.postData.filesChat = [];
      return ret;
    },
    checkModFiles() {
      let files = this.getFiles() || [];
      if (files.length > 0) return true;
      return this.isDiff(this.orgData.filesChat, this.postData.filesChat);
    },
    resetData(pData) {
      if (pData) {
        this.postData = this.deepCopy(pData);
      } else {
        this.postData = { category_id: '0', body: '' };
      }
      this.orgData = this.deepCopy(this.postData);
      this.vlData.$reset();
      this.clearFileSelect();
    },
    async post() {
      this.loading();
      let files = (this.postData.filesChat || []).concat(this.getFiles() || []);
      let fileFlag = files.find((x) => !x.deleted) ? 1 : 0;
      let params = {
        categoryId: this.postData.category_id,
        address: this.postData.address,
        body: this.postData.body,
        fileFlag: fileFlag,
      };
      params.loading = true; //ローディング表示を自動で消さない
      if (this.postData.chat_id) {
        params.chatId = this.postData.chat_id;
        await api.editChat(params);
      } else {
        let ret = await api.postChat(params);
        if (!ret.response || !ret.response.body) return;
        this.postData.chat_id = ret.response.body.chat_id;
      }
      //ファイルアップロード
      await this.uploadFiles(
        'cht',
        this.postData.chat_id,
        this.postData.chat_id,
        (this.postData.filesChat || []).filter((x) => x.deleted)
      );
      this.gaEvent('chat_post', {
        category: this.chatCategoryLabel(this.postData.category_id),
        chat_id: this.postData.chat_id,
      });

      this.postData.filesChat = [];
      this.closeModal();
      this.loaded();
      this.action();
    },
  },
};
</script>
