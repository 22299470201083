<template>
  <ListFrame
    ref="list"
    title="お知らせ"
    :totalCount="totalCount"
    :action="setList"
    :showLimit="false"
    class="list_result watch tab_01"
  >
    <div class="result_contents watch_news">
      <div
        class="result_contents_item"
        v-for="item of items"
        :key="item.id"
        :class="`${item.read_flag ? 'unread' : ''}`"
      >
        <router-link :to="`/detail_news?id=${item.contents_id}`">
          <div
            class="keyword"
            :class="isInfoWatch(item.watch_type) ? 'news' : ''"
          >
            <h3>{{ watchType(item.watch_keyword) }}</h3>
          </div>
          <div class="category">
            <p>
              <span class="news_category">{{ item.category }}</span>
            </p>
          </div>
          <div class="importance">
            <p>
              <span
                class="asideIcon important"
                :class="importanceFlag(item.importance_id) ? 'high' : ''"
              ></span>
            </p>
          </div>
          <div class="date">
            <p>{{ fmtymdS(item.display_date) }}</p>
          </div>
          <div class="title">
            <h3>{{ item.title }}</h3>
            <p class="attachedFile">
              <span v-show="item.file_flag" class="icon attached" />
            </p>
          </div>
          <div class="group">
            <p>{{ item.department_name }}</p>
          </div>
          <div class="good">
            <p class="asideIcon good type_gray">{{ item.like_number }}</p>
          </div>
          <div class="watch">
            <p>
              <span
                class="asideIcon"
                :class="isInfoWatch(item.watch_type) ? 'watch' : 'unwatch'"
              ></span>
            </p>
          </div>
        </router-link>
      </div>
    </div>
  </ListFrame>
</template>

<script>
import dataset from '../../mixin/dataset';
import api from '../../services/api';
import ListFrame from '../ListFrame.vue';
export default {
  mixins: [dataset],
  components: { ListFrame },
  created() {
    this.setList({ contentsType: this.contentsType.information }, true);
  },
  computed: {
    isInfoWatch: function () {
      return function (type) {
        return type == 'info';
      };
    },
  },
  methods: {
    async getData(params) {
      return await api.getWatchList(params);
    },
  },
};
</script>
