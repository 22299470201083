<template>
  <div
    class="file_item"
    :class="file.image_flag ? 'image' : 'default'"
    :data-fn="file.file_name"
  >
    <a :href="filePath" @click.prevent="download(file)">
      <template v-if="file.image_flag">
        <figure class="fig">
          <img :src="filePath" :alt="file.file_name" class="img_responsive" />
          <figcaption>
            {{ file.file_name }}<span>（{{ toKB(file.file_size) }}KB）</span>
          </figcaption>
        </figure>
      </template>
      <template v-else>
        {{ file.file_name }}<span>（{{ toKB(file.file_size) }}KB）</span>
      </template>
    </a>
  </div>
  <div v-if="showDel" class="file_item_delete">
    <a class="asideIcon delete" :href="filePath" @click.prevent="delFile()"
      >削除</a
    >
  </div>
</template>

<script>
import ChatImage from './ChatImage.vue';
export default {
  mixins: [ChatImage],
  data() {
    return { kind: 'info' };
  },
  computed: {
    filePath() {
      return this.getInfoFileUrl(
        this.file.file_path + '/' + this.file.file_name
      );
    },
  },
};
</script>
