import common from '../assets/js/common';

export default {
  props: {
    bodyclass: { default: '' },
  },
  data() {
    return { noScript: false };
  },
  mounted() {
    if (!this.noScript) this.excSpript();
    let cls = this.bodyclass == '' ? '' : `category_${this.bodyclass}`;
    document.body.className = cls;
  },
  // beforeUnmount() {
  // 	document.body.className = '';
  // },
  methods: {
    excSpript() {
      common();
    },
  },
};
