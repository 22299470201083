<template>
  <div class="modal" id="modal-addition_news">
    <div class="overLay modalClose confirm info"></div>
    <div class="innerModal loadData ld">
      <span class="btn modalClose outer confirm info"></span>
      <div class="innerModal_header common_header newsPost">
        <h2>お知らせ投稿{{ showPreview ? 'プレビュー' : '' }}</h2>
        <header>
          <div class="status">
            <p>
              <span
                class="tag_PostStatus"
                :class="statusClass(postData.status)"
                >{{ postData.status }}</span
              >
            </p>
          </div>
          <div class="modal_newsPostCtrl">
            <ul>
              <li>
                <a href="#" class="btn type_preview" @click.prevent="preview"
                  ><span
                    >画面プレビュー{{ showPreview ? 'を閉じる' : '' }}</span
                  ></a
                >
              </li>
              <li>
                <a
                  href="#"
                  class="btn type_detele"
                  @click.prevent="delData()"
                  v-show="postData.information_id"
                  ><span>削除</span></a
                >
              </li>
              <li>
                <button
                  href="#"
                  class="btn type_draft"
                  @click.prevent="post(true)"
                  :disabled="vld.$invalid || dateInvalid"
                >
                  下書き保存
                </button>
              </li>
              <li>
                <button
                  href="#"
                  class="btn type_publish"
                  @click.prevent="post()"
                  :disabled="vld.$invalid || dateInvalid"
                >
                  公開
                </button>
              </li>
            </ul>
          </div>
        </header>
      </div>
      <div v-show="!showPreview" class="innerModal_body newsPost heightFull">
        <div class="newsPost_group">
          <h3>タイトル<span class="required"></span> :</h3>
          <p class="note">{{ titleLength }}文字以内で入力してください。</p>
          <input
            :class="vlData.title.$invalid ? 'invalid' : ''"
            v-model="vld.postData.title.$model"
            type="text"
            placeholder="入力してください"
          />
          <span class="len">
            <span v-show="titleLenOver" style="color: red">{{
              `[入力数の制限を超えています]`
            }}</span
            >&nbsp;
            <span :style="titleLenOver ? 'color: red' : ''">{{
              `${(postData.title || '').length}`
            }}</span
            >{{ `/ ${titleLength}` }}
          </span>
        </div>
        <div class="newsPost_group">
          <h3>カテゴリ<span class="required"></span> :</h3>
          <select
            v-model="vld.postData.information_category_id.$model"
            :class="vlData.information_category_id.$invalid ? 'invalid' : ''"
          >
            <option value="-1">選択してください</option>
            <option
              v-for="item of infoCategoryItems"
              :value="item.id"
              :key="item.label"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="newsPost_group">
          <h3>重要度<span class="required"></span> :</h3>
          <p class="note">
            「高」の場合、お知らせに重要度を示すアイコンが表示されます。
          </p>
          <select v-model="vld.postData.importance_id.$model">
            <option :value="0">通常</option>
            <option :value="1">重要度:高</option>
          </select>
        </div>
        <div class="newsPost_group">
          <h3>掲載開始日時<span class="required"></span> :</h3>
          <p class="note">
            掲載期間中（掲載開始日時～掲載終了日時）のみ、お知らせに表示されます。
          </p>
          <div
            class="form_group"
            :class="vlData.publish_start_date.$invalid ? 'invalid2' : ''"
          >
            <DatePicker
              id="startDate"
              cls="dateTimePick"
              :value="fmtymdhmS(postData.publish_start_date)"
              @input="(p) => (postData.publish_start_date = p)"
              @setValid="setValid"
            />
            <p id="btnFromDateDel" class="btn_dateTime_delete">
              <img
                src="@/assets/img/common/icon_trash_gray.svg"
                alt="削除"
                class="img_responsive"
              />
            </p>
          </div>
        </div>
        <div class="newsPost_group">
          <h3>掲載終了日時<span class="required"></span> :</h3>
          <div
            class="form_group"
            :class="vlData.publish_end_date.$invalid ? 'invalid2' : ''"
          >
            <DatePicker
              id="endDate"
              cls="dateTimePick"
              :value="fmtymdhmS(postData.publish_end_date)"
              @input="(p) => (postData.publish_end_date = p)"
              @setValid="setValid2"
            />
            <p id="btnToDateDel" class="btn_dateTime_delete">
              <img
                src="@/assets/img/common/icon_trash_gray.svg"
                alt="削除"
                class="img_responsive"
              />
            </p>
          </div>
        </div>
        <div class="newsPost_group">
          <h3>表示対象<span class="required"></span> :</h3>
          <select
            v-model="vld.postData.display_target_id.$model"
            :class="vlData.display_target_id.$invalid ? 'invalid' : ''"
          >
            <option value="0">選択してください</option>
            <option
              v-for="item of displayTargetItems.filter((x) => !x.hideItem)"
              :value="item.id"
              :key="item.label"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="newsPost_group">
          <h3>投稿部門<span class="required"></span> :</h3>
          <select
            v-model="vld.postData.department_id.$model"
            :class="vlData.department_id.$invalid ? 'invalid' : ''"
          >
            <option v-show="organizationItems.length > 1" value="0">
              選択してください
            </option>
            <option
              v-for="item of organizationItems"
              :value="item.id"
              :key="item.label"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="newsPost_group">
          <div>
            <h3>
              本文<span class="required"></span> :
              <span
                v-show="vlData.body.$invalid"
                :class="vlData.body.$invalid ? 'invalid' : ''"
                >&nbsp;↓&nbsp;
              </span>
            </h3>
          </div>
          <ckeditor
            :editor="editor"
            v-model="vld.postData.body.$model"
          ></ckeditor>
          <span class="len">
            <span v-show="bodyLenOver" style="color: red">{{
              `[入力数の制限を超えています]`
            }}</span
            >&nbsp;
            <span :style="bodyLenOver ? 'color: red' : ''">{{
              `${(postData.body || '').length}`
            }}</span
            >{{ `/ ${bodyLength}` }}
          </span>
        </div>
        <div class="newsPost_group file_input">
          <h3>画像挿入位置 :</h3>
          <div class="form_group">
            <!-- 0:上、1:下、2:左、3:右 -->
            <select v-model="vld.postData.image_place.$model">
              <option value="-1">選択してください</option>
              <option value="0">上</option>
              <option value="1">下</option>
              <option value="2">左</option>
              <option value="3">右</option>
            </select>
            <div class="fileClip_wrap">
              <label class="fileClip_label type_03">
                <input type="file" multiple class="fileClip_input_type03" />
              </label>
            </div>
          </div>
          <div class="file_contents wide">
            <template v-for="(file, idx) of postData.filesView" :key="idx">
              <InfoImage
                :file="file"
                :showDel="true"
                @delete="file.deleted = true"
              />
            </template>
          </div>
        </div>
        <div class="newsPost_group">
          <h3>検索キーワード :</h3>
          <p class="note">
            キーワード検索に利用するキーワードをスペース区切りで入力して下さい。
          </p>
          <input
            v-model="vld.postData.keyword.$model"
            :class="vlData.keyword.$invalid ? 'invalid' : ''"
            type="text"
            placeholder="入力してください"
          />
        </div>
        <div class="newsPost_group">
          <h3>添付ファイル</h3>
          <div class="file_group file_input">
            <div class="fileClip_wrap">
              <label class="fileClip_label type_02">
                <input type="file" multiple class="fileClip_input_type02" />
              </label>
            </div>
            <div class="file_contents">
              <template v-for="(file, idx) of postData.files" :key="idx">
                <InfoImage
                  :file="file"
                  :showDel="true"
                  @delete="file.deleted = true"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- > ------------------------- 以下プレビュー表示 -------------------------  <-->
      <div
        v-show="showPreview"
        class="innerModal_body list_result detail_news heightFull"
      >
        <div class="loadData">
          <div class="newsDetail_title">
            <h2>
              {{ postData.title }}
            </h2>
            <div>
              <h3>{{ organizationLabel(postData.department_id) }}</h3>
            </div>
          </div>
          <div class="newsDetail_contents">
            <div class="metaData">
              <div class="metaData_01">
                <ul>
                  <li>
                    <span class="news_category">{{ category }}</span>
                  </li>
                  <li>
                    <span
                      class="asideIcon important"
                      :class="
                        importanceFlag(postData.importance_id)
                          ? 'high'
                          : 'normal'
                      "
                    ></span>
                  </li>
                  <li>{{ fmtymdhmS(postData.publish_start_date) }}</li>
                </ul>
              </div>
              <div class="metaData_02">
                <ul>
                  <li><span class="asideIcon good type_gray">——</span></li>
                  <li><span class="asideIcon watch type_pink"></span></li>
                </ul>
              </div>
            </div>
            <div
              class="main_contents"
              :class="`imageLayout_${position(postData.image_place)}`"
            >
              <figure class="img_prev">
                <template v-for="(file, idx) of filesView" :key="idx">
                  <img
                    :src="getInfoFileUrl(file.file_path + '/' + file.file_name)"
                    class="img_responsive"
                  />
                </template>
              </figure>
              <div class="txt_contents" v-html="$sanitize(body)"></div>
            </div>
            <div class="file_contents img_attach">
              <template v-for="(file, idx) of filesAttach" :key="idx">
                <figure class="attach_item">
                  <InfoImage :file="file" />
                </figure>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../services/api';
import useVuelidate from '@vuelidate/core';
import {
  required,
  minValue,
  maxLength,
  not,
  sameAs,
} from '@vuelidate/validators';
import InfoImage from '../components/InfoImage.vue';
import DatePicker from './DatePicker';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
export default {
  components: { DatePicker, InfoImage },
  props: {
    action: { default: () => {} },
  },
  setup() {
    return { vld: useVuelidate() };
  },
  computed: {
    body() {
      // return this.postData.body;
      if (!this.postData.body) return '';
      return this.unescapeHtml(this.postData.body).replaceAll('\\"', '"');
    },
    category() {
      if (this.postData.category) return this.postData.category;
      return this.infoCategoryLabel(this.postData.information_category_id);
    },
    vlData() {
      return this.vld.postData;
    },
    filesAttach() {
      return (this.postData.files || []).filter((x) => !x.deleted);
    },
    filesView() {
      return (this.postData.filesView || []).filter((x) => !x.deleted);
    },
    bodyLenOver() {
      return (this.postData.body || '').length > this.bodyLength;
    },
    titleLenOver() {
      return (this.postData.title || '').length > this.titleLength;
    },
  },
  data() {
    return {
      bodyLength: 5000,
      titleLength: 50,
      orgData: {},
      postData: {},
      showPreview: false,
      editor: Editor,
    };
  },
  validations() {
    return {
      postData: {
        title: { required, maxLength: maxLength(this.titleLength) },
        information_category_id: { required, minValue: minValue(0) },
        importance_id: {},
        display_target_id: { minValue: minValue(1) },
        department_id: { minValue: not(sameAs('0')) },
        body: { required, maxLength: maxLength(this.bodyLength) },
        image_place: {},
        keyword: { maxLength: maxLength(1000) },
        publish_start_date: { required },
        publish_end_date: { required },
      },
    };
  },
  mounted() {
    //キャンセル操作のクラスと紐づける
    this.setConfirm('info', this.cancel);
  },
  methods: {
    async cancel() {
      if (
        this.vlData.title.$dirty ||
        this.vlData.information_category_id.$dirty ||
        this.vlData.importance_id.$dirty ||
        this.vlData.display_target_id.$dirty ||
        this.vlData.department_id.$dirty ||
        this.vlData.body.$dirty ||
        this.vlData.image_place.$dirty ||
        this.vlData.keyword.$dirty ||
        this.vlData.publish_start_date.$dirty ||
        this.vlData.publish_end_date.$dirty ||
        this.checkModFiles()
      ) {
        let ret = await this.confirm('編集内容を取り消してもよろしいですか？');
        if (!ret) return;
      }
      this.postData.files = [];
      this.postData.filesView = [];
      this.closeModal();
    },
    checkModFiles() {
      if (this.orgData.publish_start_date != this.postData.publish_start_date)
        return true;
      if (this.orgData.publish_end_date != this.postData.publish_end_date)
        return true;
      let files = this.getFiles() || [];
      if (files.length > 0) return true;
      let ret = this.isDiff(this.orgData.files, this.postData.files);
      if (ret) return true;
      return this.isDiff(this.orgData.filesView, this.postData.filesView);
    },
    async resetData(id) {
      let items = [];
      if (id != -1) {
        this.loading();
        items = await this.getData({ informationId: id, postFlag: 1 });
      }
      this.postData = this.getItemData(items);
      if (this.postData.department_name) {
        this.postData.department_id = this.organizationId(
          this.postData.department_name
        );
      }
      this.information_category_id = this.infoCategoryId(
        this.postData.category
      );
      if (this.postData.image_place == null) this.postData.image_place = -1;
      if (!this.postData.files) this.postData.files = [];
      if (!this.postData.filesView) this.postData.filesView = [];

      this.orgData = this.deepCopy(this.postData);
      this.showPreview = false;
      this.clearFileSelect();
      setTimeout(this.setDateClear, 100);
      setTimeout(this.vld.postData.$reset, 100);
      if (!this.postData.publish_start_date) {
        document.getElementById('btnFromDateDel').click();
      }
      if (!this.postData.publish_end_date) {
        document.getElementById('btnToDateDel').click();
      }
    },
    getItemData(items) {
      return items.length > 0
        ? items[0]
        : {
            information_category_id: -1,
            importance_id: 0,
            display_target_id: 0,
            department_id:
              this.organizationItems.length == 1
                ? this.organizationItems[0].id
                : '0',
          };
    },
    async getData(params) {
      let ret = await api.getInformationDetail(params);
      return ret.contents || [];
    },
    preview() {
      this.showPreview = !this.showPreview;
    },
    async delData() {
      let ret = await this.confirm();
      if (!ret) return;
      let params = {
        informationId: this.postData.information_id,
        deleteFlag: 1,
      };
      await api.editInformation(params);
      this.closeCommon();
    },
    closeCommon() {
      this.action();
      // this.removeItem('img_responsive');
      if (this.modalShowing) this.closeModal();
    },
    async post(draft) {
      if (
        !this.compareDateTime(
          this.postData.publish_end_date,
          this.postData.publish_start_date
        )
      ) {
        await this.showInfo(
          '掲載終了日時は掲載開始日時以降の日時に設定する必要があります'
        );
        return;
      }
      let viewFiles = this.getFiles().filter((x) => x.file_div == '1');
      let filesView = this.postData.filesView
        .concat(viewFiles)
        .filter((x) => !x.deleted);
      if (this.postData.image_place == '-1' && filesView.length > 0) {
        await this.showInfo('画像表示の挿入位置を選択してください');
        return;
      }
      this.loading();
      //データ登録
      let atchFiles = this.getFiles().filter((x) => x.file_div != '1');
      let files = this.postData.files
        .concat(atchFiles)
        .filter((x) => !x.deleted);
      let params = {
        title: this.postData.title,
        categoryId: this.postData.information_category_id,
        importanceId: this.postData.importance_id,
        publishStartDate: this.postData.publish_start_date,
        publishEndDate: this.postData.publish_end_date,
        displayTargetId: this.postData.display_target_id,
        organizationCode: this.postData.department_id,
        body: this.postData.body,
        keyword: this.postData.keyword || '',
        status: draft ? 0 : 1,
        fileFlag: files.length > 0 ? 1 : 0,
        imagePlace:
          this.postData.image_place == -1 ? null : this.postData.image_place,
      };
      params.loading = true; //ローディング表示を自動で消さない
      if (this.postData.information_id) {
        params.informationId = this.postData.information_id;
        await api.editInformation(params);
      } else {
        let ret = await api.postInformation(params);
        if (!ret.response || !ret.response.body) return;
        this.postData.information_id = ret.response.body.information_id;
      }
      //ファイルアップロード
      let delFiles = this.postData.files
        .concat(this.postData.filesView)
        .filter((x) => x.deleted);
      await this.uploadFiles(
        'info',
        this.postData.information_id,
        this.postData.information_id,
        delFiles
      );
      this.postData.files = [];
      this.postData.filesView = [];
      this.closeCommon();
      this.loaded();
    },
  },
};
</script>
<style scoped>
.attach_item {
  margin-left: 18px;
}
.len {
  /* text-align: right; */
  float: right;
}
</style>
<style scoped lang="scss">
.txt_contents :deep() {
  a {
    color: #ff6464 !important;
  }
  .text-tiny {
    font-size: 0.7em;
  }
  .text-small {
    font-size: 0.85em;
  }
  .text-big {
    font-size: 1.4em;
  }
  .text-huge {
    font-size: 1.8em;
  }
  h2,
  h3,
  h4 {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    margin: 0;
    font-weight: 700;
  }
  h2 {
    font-size: 2.4rem !important;
  }
  h3 {
    font-size: 2.2rem !important;
  }
  h4 {
    font-size: 2rem !important;
  }
  ul {
    list-style-position: outside !important;
    list-style-type: disc !important;
    padding-left: 1.1em !important;
  }
  ol {
    list-style-position: outside !important;
    list-style-type: decimal !important;
    padding-left: 1.2em !important;
  }
  blockquote {
    background: rgba(233, 236, 238, 0.4) !important;
    border-left: 4px solid #ffb8b2 !important;
    padding: 5px 5px 5px 16px !important;
    margin: 5px 0 !important;
  }
  code {
    color: #ff6464 !important;
    font-size: 1.3rem !important;
    background: #fff !important;
    border: 1px solid #d4d4d4 !important;
    border-radius: 6px !important;
    padding: 2px 4px !important;
    margin: 0 2px !important;
  }
}
.invalid,
.invalid2 > input {
  background-color: pink;
}
</style>
