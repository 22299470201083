export default {
  async upload(url, file) {
    return new Promise((resolve, reject) => {
      // サーバへ送信
      let send = (data) => {
        // formData.append('MAX_FILE_SIZE', MAX_FILE_SIZE); // ファイルより先に追加する
        const param = {
          method: 'PUT',
          body: data,
        };
        fetch(url, param)
          .then((res) => {
            if (!res.ok) {
              throw new Error(`Fetch: ${res.status} ${res.statusText}`);
            }
            resolve();
          })
          .catch((error) => {
            console.error(`[Fetch] ${error}, ${url}`);
            reject();
          });
      };
      const reader = new FileReader();
      reader.onload = function (evt) {
        send(evt.target.result);
      };
      reader.readAsArrayBuffer(file);
    });
  },
  downloadFile(url, label) {
    fetch(url, { responseType: 'blob' })
      .then(async (res) => {
        const blob = await res.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  },
};
