<template>
  <input
    v-if="cls == 'datePick'"
    :id="id"
    v-model="inputValue"
    type="text"
    placeholder="日付を選択"
    class="type_select datePick"
    autocomplete="off"
  />
  <input
    v-else
    :id="id"
    v-model="inputValue"
    type="text"
    placeholder="日時を選択"
    class="type_select dateTimePick"
    autocomplete="off"
  />
</template>

<script>
export default {
  name: 'DatePicker',
  emits: ['input', 'invalid'],
  props: {
    id: { default: 'topics' },
    cls: { default: 'datePick' },
    value: null,
  },
  computed: {
    inputValue: {
      get() {
        return this.value ? this.value.replaceAll('-', '/') : this.value;
      },
      set(newValue) {
        let elm = document.getElementById(this.id);
        elm.classList.remove('inv');

        let invalid = false;
        if (newValue) {
          let regStr =
            this.cls == 'datePick'
              ? /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/
              : /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01]) ([01][0-9]|2[0-3]):[0-5][0-9]$/;
          invalid = !this.isValidDate(newValue) || !regStr.test(newValue);
          if (invalid) elm.classList.add('inv');
        }
        this.$emit('input', newValue);
        this.$emit('setValid', invalid);
      },
    },
  },
  mounted() {
    this.setupDatePicker(this.id);
  },
};
</script>
<style scoped>
.inv {
  background-color: pink !important;
}
</style>
