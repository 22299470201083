<template>
  <SearchFrame title="チャット" kind="chat">
    <SearchFrameChat title="チャット" ref="search" :action="setList" />
  </SearchFrame>
  <nav class="addition">
    <button
      id="newPost"
      class="btn type_addition modalOpen"
      data-modal="#modal-chat"
      @click.prevent="$refs.dlg.resetData()"
    >
      新規投稿
    </button>
  </nav>
  <ListFrame
    ref="list"
    :totalCount="totalCount"
    :action="setList"
    class="list_result chat"
  >
    <div class="result_contents">
      <nav class="sort">
        <div class="sort_item category" data-sort="category_id">
          <h3>カテ<br class="hidden_SP" />ゴリー</h3>
        </div>
        <div class="sort_item group" data-sort="organization_name">
          <h3>部門</h3>
        </div>
        <div class="sort_item author" data-sort="user_name">
          <h3>投稿者</h3>
        </div>
        <!--★PC表示用-->
        <div class="sort_item line2 date for_pc">
          <span class="sort_item" data-sort="create_datetime"
            ><h3>投稿日</h3></span
          >
          <span class="sort_item" data-sort="update_datetime"
            ><h3>更新日</h3></span
          >
        </div>
        <!--★SP表示用-->
        <div
          class="sort_item date date_padding for_sp"
          data-sort="create_datetime"
        >
          <h3>投稿日</h3>
        </div>
        <!--★SP表示用-->
        <div class="sort_item date for_sp" data-sort="update_datetime">
          <h3>更新日</h3>
        </div>
        <div class="sort_item to" data-sort="address">
          <h3>宛名</h3>
        </div>
        <div class="sort_item description" data-sort="body">
          <h3>本文</h3>
        </div>
        <div class="attached">
          <!--タイトル表記なし-->
        </div>
        <div class="sort_item good" data-sort="like_number">
          <h3>
            <img src="@/assets/img/common/icon_good_gray.svg" alt="いいね" />
          </h3>
        </div>
        <div class="sort_item comment" data-sort="reply_number">
          <h3>
            <img
              src="@/assets/img/common/icon_comment_gray.svg"
              alt="コメント"
            />
          </h3>
        </div>
      </nav>

      <div
        class="result_contents_item"
        :class="`${item.read_flag ? 'unread' : ''} bg_${categoryIcon(
          item.category_id
        )}`"
        v-for="item of items"
        :key="item.id"
      >
        <router-link :to="`/detail_chat?id=${item.chat_id}`">
          <div class="category">
            <p>
              <span
                class="chatIcon"
                :class="categoryIcon(item.category_id)"
              ></span>
            </p>
          </div>
          <div class="sp_wrap">
            <div class="group">
              <p>{{ item.organization_name }}</p>
            </div>
            <div class="author">
              <h3>{{ item.user_name }}</h3>
            </div>
            <div class="date">
              <p>
                投稿：{{ fmtymdS(item.create_datetime) }}<br />(更新：{{
                  fmtymdS(item.update_datetime)
                }})
              </p>
            </div>
            <div class="to">
              <h4 class="destination">
                <span>TO</span>
                <strong>{{ item.address }}</strong>
              </h4>
            </div>
            <div class="description container">
              <h3 class="ellipsis">{{ item.body }}</h3>
            </div>
            <div class="attached">
              <p>
                <span class="icon" :class="item.file_flag ? 'attached' : ''" />
              </p>
            </div>
            <div class="good">
              <p class="asideIcon good type_gray">{{ item.like_number }}</p>
            </div>
            <div class="comment">
              <p class="asideIcon comment">{{ item.reply_number }}</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </ListFrame>
  <ChatDialog :action="conditionSearch" ref="dlg" />
</template>
<script>
import view from '../mixin/view';
import api from '../services/api';
import dataset from '../mixin/dataset';
import SearchFrame from '../components/SearchFrame.vue';
import SearchFrameChat from '../components/SearchFrameChat.vue';
import ListFrame from '../components/ListFrame.vue';
import ChatDialog from '../components/ChatDialog.vue';
export default {
  name: 'ChatList',
  components: { SearchFrame, SearchFrameChat, ListFrame, ChatDialog },
  mixins: [view, dataset],
  // props: {
  // 	// msg: String,
  // },
  methods: {
    async getData(params) {
      if (!params.orders) params.orders = '-update_datetime';
      return await api.getChatList(params);
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (to.path.startsWith('/detail_chat')) {
      await this.setIdList('chat_id');
    }
    next();
  },
  mounted() {
    this.conditionSearch();
    let params = this.$store.getters.getTempParams();
    if (params) {
      let self = this;
      setTimeout(() => {
        self.openModal(params.modalId);
      }, 300);
    }
  },
};
</script>
