<template>
  <section class="list_result detail_page">
    <div><span></span></div>
    <header>
      <div class="title">
        <p class="back">
          <router-link :to="`/`"> ホームへ戻る </router-link>
        </p>
      </div>
    </header>
    <div class="pageDetail_title">
      <span v-if="div == 'error'">
        <h2>エラーが発生しました</h2>
      </span>
      <span v-else>
        <h2>ページが存在しません</h2>
      </span>
    </div>
    <div class="pageDetail_contents">
      <div v-if="div == 'error'" class="main_contents">
        <strong>エラーが発生しました</strong>
        <div>再度操作を試してください</div>
      </div>
      <div v-else class="main_contents">
        <strong>指定したページは存在しないか、データが削除されました</strong>
      </div>
    </div>
    <footer>
      <p class="back">
        <router-link :to="`/`"> ホームへ戻る </router-link>
      </p>
      <nav class="paging"></nav>
    </footer>
  </section>
</template>
<script>
export default {
  props: {
    div: { default: 'notFound' },
  },
};
</script>
<style scoped>
strong {
  color: red;
  vertical-align: middle;
  font-size: 16px;
}
</style>
