<template>
  <ThredFrame kind="news" pageName="お知らせ">
    <div v-if="noAuth" class="newsDetail_contents">
      <h2 class="noAuth">閲覧権限がありません</h2>
    </div>
    <div v-if="!noAuth" class="newsDetail_title">
      <h2>{{ postData.title }}</h2>
      <div>
        <h3>{{ postData.department_name }}</h3>
        <p v-show="(postData.files || []).length > 0">
          <button class="btn type_download" @click="downloadFiles">
            ダウンロード
          </button>
        </p>
      </div>
    </div>
    <div v-if="!noAuth" class="newsDetail_contents">
      <div class="metaData">
        <div class="metaData_01">
          <ul>
            <li>
              <span class="news_category">{{ postData.category }}</span>
            </li>
            <li>
              <span
                class="asideIcon important"
                :class="
                  importanceFlag(postData.importance_id) ? 'high' : 'normal'
                "
              ></span>
            </li>
            <li>{{ fmtymdhmS(postData.publish_start_date) }}</li>
          </ul>
        </div>
        <div class="metaData_02">
          <ul>
            <li>
              <span
                class="asideIcon good"
                :class="postData.like_flag ? '' : 'type_gray'"
                @click.prevent="editLike()"
                >{{ postData.like_number }}</span
              >
            </li>
            <li>
              <span
                class="asideIcon watch"
                :class="postData.watch_flag ? 'type_pink' : 'unwatch'"
                @click.prevent="editWatch()"
              ></span>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="main_contents"
        :class="`imageLayout_${position(postData.image_place)}`"
      >
        <figure>
          <template v-for="(file, idx) of postData.filesView" :key="idx">
            <img
              :src="getInfoFileUrl(file.file_path + '/' + file.file_name)"
              :alt="file.file_name"
              class="img_responsive"
            />
          </template>
        </figure>
        <div class="txt_contents" v-html="$sanitize(body)"></div>
      </div>
      <div class="file_contents">
        <template v-for="(file, idx) of postData.files" :key="idx">
          <InfoImage :file="file" :title="postData.title" :canDownload="true" />
        </template>
      </div>
    </div>
  </ThredFrame>
</template>
<script>
import view from '../mixin/view';
import api from '../services/api';
import dataset from '../mixin/dataset';
import thread from '../mixin/thread';
import ThredFrame from '../components/ThredFrame.vue';
import InfoImage from '../components/InfoImage.vue';
export default {
  name: 'Information',
  components: { ThredFrame, InfoImage },
  mixins: [view, dataset, thread],
  computed: {
    postData() {
      return this.items.length > 0 ? this.items[0] : {};
    },
    body() {
      if (!this.postData.body) return '';
      return this.unescapeHtml(this.postData.body).replaceAll('\\"', '"');
    },
  },

  methods: {
    async setItems() {
      let id = this.$route.query.id;
      await this.setList({ informationId: id });
    },
    async getData(params) {
      return await api.getInformationDetail(params);
    },
    async editLike() {
      let params = {
        contentsType: this.contentsType.information,
        contentsId: this.postData.information_id,
      };
      await api.editLike(params);
      this.postData.like_flag = !this.postData.like_flag || false;
      let num = Number(this.postData.like_number);
      this.postData.like_number = num + (this.postData.like_flag ? 1 : -1);
    },
    async editWatch() {
      let params = {
        informationId: this.postData.information_id,
      };
      await api.registerInformationWatch(params);
      this.postData.watch_flag = !this.postData.watch_flag || false;
      // this.setList();
    },
    async downloadFiles() {
      await this.downloadFile(
        'info',
        this.postData.information_id,
        null,
        this.postData.title
      );
    },
  },
};
</script>
