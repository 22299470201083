<template>
  <ThredFrame kind="chat" pageName="チャット">
    <div class="chatDetail_contents loadData">
      <div class="loader"><span></span></div>
      <div class="mainPost">
        <header>
          <div class="status">
            <p v-show="!postData.closed_flag" class="accepting">返信受付中</p>
            <p v-show="postData.closed_flag" class="closed">返信受付終了</p>
          </div>
          <h2>
            <span>{{ postData.chat_organization_name }}</span>
            <strong>{{ postData.chat_user_name }}</strong>
          </h2>
          <div class="authorMenu">
            <ul>
              <li v-if="!ownPost" />
              <li v-if="ownPost">
                <a
                  v-if="!postData.closed_flag"
                  href="#"
                  class="btn type_closing"
                  @click.prevent="editColse()"
                  >返信受付終了</a
                >
                <a
                  v-if="postData.closed_flag"
                  href="#"
                  class="btn type_closing"
                  @click.prevent="editColse()"
                  >返信受付を再開する</a
                >
              </li>
              <li
                class="asideIcon good"
                :class="postData.chat_like_flag ? '' : 'type_gray'"
                @click.prevent="editLike('cht', postData.chat_id)"
              >
                {{ postData.like_number }}
              </li>
              <li class="asideIcon comment">{{ postData.reply_number }}</li>
            </ul>
          </div>
        </header>
        <div class="comment">
          <div class="category">
            <span
              class="chatIcon"
              :class="categoryIcon(postData.category_id)"
            ></span>
          </div>
          <div class="body">
            <header>
              <div class="meta">
                <h3 class="destination">
                  <span>TO</span>
                  <strong>{{ postData.address }}</strong>
                </h3>
                <p class="date">
                  投稿：{{ fmtymdhmS(postData.chat_create_datetime)
                  }}<br class="hidden_PC hidden_TAB" />(更新：{{
                    fmtymdhmS(postData.chat_update_datetime)
                  }})
                </p>
              </div>
              <div class="ctrl">
                <ul>
                  <li v-if="!postData.closed_flag && ownPost">
                    <a
                      href="#"
                      class="modalOpen ctrlIcon edit"
                      title="編集"
                      data-modal="#modal-chat"
                      @click.prevent="$refs.dlg.resetData(postData)"
                      >編集</a
                    >
                  </li>
                  <li
                    v-if="
                      (!postData.closed_flag && ownPost) ||
                      userInfo.authority_chat_delete == '1'
                    "
                  >
                    <a
                      href="#"
                      class="ctrlIcon delete"
                      title="削除"
                      @click.prevent="deleteChat()"
                      >削除</a
                    >
                  </li>
                </ul>
              </div>
            </header>
            <div class="txt_contents">
              <p v-html="chatText(postData.body)"></p>
            </div>
            <div class="file_contents">
              <template v-for="(file, idx) of postData.filesChat" :key="idx">
                <ChatImage
                  :file="file"
                  :title="postData.chat_id"
                  :canDownload="true"
                />
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="replyPost">
        <div
          v-for="(item, idx) of replyItems"
          :key="idx"
          class="replyPost_item"
        >
          <template v-if="item.reply_delete_flag">
            <div style="color: gray">(コメントは削除されました)</div>
          </template>
          <template v-else>
            <div class="ctrl">
              <ul>
                <li v-if="!postData.closed_flag && ownReply(item)">
                  <a
                    href="#"
                    class="modalOpen ctrlIcon edit"
                    title="編集"
                    data-modal="#modal-chat_reply"
                    @click.prevent="$refs.dlgRep.resetData(idx)"
                    >編集</a
                  >
                </li>
                <li
                  v-if="
                    (!postData.closed_flag && ownReply(item)) ||
                    userInfo.authority_chat_delete == '1'
                  "
                >
                  <a
                    href="#"
                    class="ctrlIcon delete"
                    title="削除"
                    @click.prevent="deleteReply(item.chat_reply_id)"
                    >削除</a
                  >
                </li>
              </ul>
            </div>

            <div class="replyUser">
              <div
                class="replyCategory"
                :class="item.best_answer_flag ? 'best' : ''"
              >
                ベストアンサー
              </div>
              <div class="userData">
                <h3>
                  <span>{{ item.reply_organization_name }}</span>
                  <strong>{{ item.reply_user_name }}</strong>
                </h3>
                <p class="date">
                  投稿：{{ fmtymdhmS(item.reply_create_datetime)
                  }}<br class="hidden_TAB" />(更新：{{
                    fmtymdhmS(item.reply_update_datetime)
                  }})
                </p>
              </div>
            </div>
            <div class="replyBody">
              <div class="txt_contents">
                <p>{{ item.comment }}</p>
              </div>
              <div class="file_contents">
                <template v-for="(file, idx) of item.files" :key="idx">
                  <ChatImage
                    :file="file"
                    :title="postData.chat_id"
                    :canDownload="true"
                  />
                </template>
              </div>
            </div>
            <div class="replyIcons">
              <ul>
                <li
                  class="asideIcon good"
                  :class="item.reply_like_flag ? '' : 'type_gray'"
                  @click.prevent="editLike('chtrp', item.chat_reply_id)"
                >
                  {{ item.reply_like_number }}
                </li>
                <li>
                  <a
                    class="ctrlIcon"
                    v-if="!ownPost || postData.closed_flag || bestAnswer"
                  />
                  <a
                    v-else
                    href="#"
                    class="ctrlIcon"
                    :class="item.best_answer_flag ? 'best_on' : 'best_off'"
                    @click.prevent="setBestAnswer(item)"
                  />
                </li>
              </ul>
            </div>
          </template>
        </div>
        <nav class="reply" v-if="!postData.closed_flag">
          <p>
            <a
              href="#"
              class="modalOpen btn type_reply"
              data-modal="#modal-chat_reply"
              @click.prevent="$refs.dlgRep.resetData(-1)"
              >返信</a
            >
          </p>
        </nav>
      </div>
    </div>
  </ThredFrame>
  <ChatDialog :action="setItems" ref="dlg" />
  <ChatReplyDialog
    :pData="postData"
    :pItems="replyItems"
    :action="setItems"
    ref="dlgRep"
  />
</template>
<script>
import view from '../mixin/view';
import api from '../services/api';
import dataset from '../mixin/dataset';
import thread from '../mixin/thread';
import ThredFrame from '../components/ThredFrame.vue';
import ChatDialog from '../components/ChatDialog.vue';
import ChatReplyDialog from '../components/ChatReplyDialog.vue';
import ChatImage from '../components/ChatImage.vue';
import common from '../assets/js/common';
export default {
  name: 'Chat',
  components: { ThredFrame, ChatDialog, ChatReplyDialog, ChatImage },
  mixins: [view, dataset, thread],
  data() {
    return { userInfo: {} };
  },
  async created() {
    this.userInfo = await api.getUserInfo();
  },
  computed: {
    postData() {
      return this.items.length > 0 ? this.items[0] : {};
    },
    replyItems() {
      return this.items.filter((x) => x.reply_create_user_id);
    },
    bestAnswer() {
      return this.replyItems.find((x) => x.best_answer_flag);
    },
    ownPost() {
      return this.userInfo.user_id == this.postData.chat_create_user_id;
    },
    ownReply() {
      return (item) => {
        return this.userInfo.user_id == item.reply_create_user_id;
      };
    },
    chatText() {
      return (text) => {
        return text ? text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1'>$1</a>") : "";
      };
    },
  },
  watch: {
    items() {
      common();
    },
  },
  methods: {
    async setItems() {
      this.loading();
      let id = this.$route.query.id;
      await this.setList({ chatId: id });
      this.loaded();
    },
    async getData(params) {
      return await api.getChatDetail(params);
    },
    async deleteChat() {
      let ret = await this.confirm();
      if (!ret) return;
      let params = {
        chatId: this.postData.chat_id,
        deleteFlag: 1,
      };
      await api.editChat(params);
      this.$router.push('/list_chat');
      this.pushGA('chat_delete');
    },
    pushGA(evname) {
      this.gaEvent(evname, {
        category: this.chatCategoryLabel(this.postData.category_id),
        chat_id: this.postData.chat_id,
      });
    },
    async deleteReply(id) {
      let ret = await this.confirm();
      if (!ret) return;
      let params = {
        chatId: this.postData.chat_id,
        chatReplyId: id,
        deleteFlag: 1,
      };
      await api.editComment(params);
      this.setItems();
    },
    async editColse() {
      if (this.postData.closed_flag) {
        if (this.bestAnswer) {
          let ret = await this.confirm(
            '再開すると設定しているベストアンサーが解除されますがよろしいですか？'
          );
          if (!ret) return;
          await this.registerBestAnswer(this.bestAnswer.chat_reply_id);
        }
      }
      let params = {
        chatId: this.postData.chat_id,
        closedFlag: this.postData.closed_flag ? '0' : '1',
      };
      await api.registerChatClosed(params);

      let evname = this.postData.closed_flag ? 'chat_restart' : 'chat_complete';
      this.pushGA(evname);

      this.setItems();
    },
    async editLike(type, id) {
      if (this.postData.closed_flag) return;
      let params = {
        contentsType: type,
        contentsId: id,
      };
      await api.editLike(params);
      this.setItems();
    },
    async setBestAnswer(item) {
      if (this.postData.closed_flag) return;
      await this.registerBestAnswer(item.chat_reply_id);
      if (!item.best_answer_flag) {
        //ベストアンサーに設定したときにクローズする
        await this.editColse();
      }
      this.setItems();
    },
    async registerBestAnswer(id) {
      await api.registerChatBestAnswer({ chatReplyId: id });
    },
  },
};
</script>
