<template>
  <nav class="page_nav" :class="`${kind}_pc`">
    <!-- PC用表示 -->
    <template v-if="!isSPMode">
      <slot />
    </template>
  </nav>

  <nav class="page_nav fixed" :class="`${kind}_sp`">
    <h2>{{ title }}一覧</h2>
    <p class="filter">
      <a href="#" class="modalOpen" data-modal="#modal-search_topics"
        >検索条件</a
      >
    </p>
  </nav>
  <div class="modal" id="modal-search_topics">
    <div class="overLay modalClose"></div>

    <div class="innerModal">
      <span class="btn modalClose outer"></span>
      <div class="innerModal_header sp_header">
        <h2>{{ title }}検索</h2>
        <p class="filter">検索条件</p>
      </div>
      <!--/innerModal_header-->
      <div
        class="innerModal_body sp_search"
        :class="`${kind == 'topics' ? '' : 'type_left heightFull'}`"
      >
        <!-- SP用表示 -->
        <template v-if="isSPMode">
          <slot />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchFrame',
  props: {
    kind: { default: 'topics' },
    title: { default: '' },
  },
};
</script>
