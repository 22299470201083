<template>
  <div class="modal" id="modal-chat_reply">
    <div class="overLay modalClose confirm rep"></div>
    <div class="innerModal loadData ld">
      <span class="btn modalClose outer confirm rep"></span>
      <div class="innerModal_header common_header">
        <h2 v-show="pIndex == -1">チャット返信</h2>
        <h2 v-show="pIndex > -1">チャット返信編集</h2>
      </div>
      <div class="innerModal_body chat_edit chat autoScroll_half">
        <div class="autoScroll_item">
          <div class="modal_mainPost">
            <div class="comment">
              <div class="category">
                <span
                  class="chatIcon"
                  :class="categoryIcon(postData.category_id)"
                ></span>
              </div>
              <h2>
                <span>{{ postData.chat_organization_name }}</span>
                <strong>{{ postData.chat_user_name }}</strong>
              </h2>
              <div class="body">
                <header>
                  <div class="meta">
                    <h3 class="destination">
                      <span>TO</span>
                      <strong>{{ postData.address }}</strong>
                    </h3>
                    <p class="date">
                      投稿：{{ fmtymdhmS(postData.chat_create_datetime)
                      }}<br class="hidden_PC hidden_TAB" />(更新：{{
                        fmtymdhmS(postData.chat_update_datetime)
                      }})
                    </p>
                  </div>
                </header>
                <div class="txt_contents">
                  <p>{{ postData.body }}</p>
                </div>
                <div class="file_contents">
                  <template
                    v-for="(file, idx) of postData.filesChat"
                    :key="idx"
                  >
                    <ChatImage :file="file" />
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_replyPost">
            <div
              v-for="(item, idx) of replyItems"
              :key="idx"
              class="replyPost_item"
            >
              <template v-if="item.reply_delete_flag">
                <div style="color: gray">(コメントは削除されました)</div>
              </template>
              <template v-else>
                <div class="replyUser">
                  <div
                    class="replyCategory"
                    :class="item.best_answer_flag ? 'best' : ''"
                  >
                    ベストアンサー
                  </div>
                  <div class="userData">
                    <h3>
                      <span>{{ item.reply_organization_name }}</span>
                      <strong>{{ item.reply_user_name }}</strong>
                    </h3>
                    <p class="date">
                      投稿：{{ fmtymdhmS(item.reply_create_datetime)
                      }}<br class="hidden_PC hidden_TAB" />(更新：{{
                        fmtymdhmS(item.reply_update_datetime)
                      }})
                    </p>
                  </div>
                </div>
                <div class="replyBody">
                  <div class="txt_contents">
                    <p>{{ item.comment }}</p>
                  </div>
                  <div class="file_contents">
                    <template v-for="(file, idx) of item.files" :key="idx">
                      <ChatImage :file="file" />
                    </template>
                  </div>
                </div>
                <div class="replyIcons">
                  <ul>
                    <li class="asideIcon good type_gray">
                      {{ item.reply_like_number }}
                    </li>
                    <li>
                      <a
                        class="ctrlIcon"
                        v-if="postData.closed_flag || bestAnswer"
                      />
                      <a
                        v-else
                        title="ベストアンサー"
                        class="ctrlIcon"
                        :class="item.best_answer_flag ? 'best_on' : 'best_off'"
                      />
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </div>
        </div>

        <hr />
        <div class="autoScroll_item">
          <div class="body">
            <h3>コメント：</h3>
            <textarea v-model.trim="vld.replyData.comment.$model" />
          </div>
          <div class="file_contents file_input">
            <template v-for="(file, idx) of replyData.files" :key="idx">
              <ChatImage
                :file="file"
                :showDel="true"
                @delete="file.deleted = true"
              />
            </template>
            <div class="fileClip_wrap">
              <label class="fileClip_label">
                <input type="file" multiple class="fileClip_input" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="innerModal_footer">
        <nav class="modal_ctrl">
          <div class="note alert">
            <p>
              ※添付ファイルを含む投稿内容は、全てのサイト利用者が閲覧できます。
            </p>
          </div>
          <div class="ctrlArea">
            <ul>
              <li>
                <button class="btn type_cancel modalClose confirm rep">
                  キャンセル
                </button>
              </li>
              <li>
                <button
                  v-show="pIndex == -1"
                  class="btn type_reply"
                  @click="post"
                  :disabled="vld.$invalid"
                >
                  返信
                </button>
                <button
                  v-show="pIndex > -1"
                  class="btn type_save"
                  @click="post"
                  :disabled="vld.$invalid"
                >
                  保存
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../services/api';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import ChatImage from '../components/ChatImage.vue';
export default {
  components: { ChatImage },
  props: {
    pData: { default: {} },
    pItems: { default: [] },
    action: { default: () => {} },
  },
  setup() {
    return { vld: useVuelidate() };
  },
  validations() {
    return {
      replyData: {
        comment: { required, maxLength: maxLength(1000) },
      },
    };
  },
  data() {
    return {
      pIndex: -1,
      postData: {},
      replyItems: {},
      replyData: {},
      orgData: {},
    };
  },
  mounted() {
    this.setConfirm('rep', this.cancel);
  },
  computed: {
    bestAnswer() {
      return this.replyItems.find((x) => x.best_answer_flag);
    },
  },
  watch: {
    pData() {
      this.postData = this.pData;
    },
    pItems() {
      this.replyItems = this.pItems;
    },
  },
  methods: {
    async cancel() {
      let ret = true;
      if (this.vld.replyData.comment.$dirty || this.checkModFiles()) {
        ret = await this.confirm('編集内容を取り消してもよろしいですか？');
      }
      if (ret) this.replyData.files = [];
      return ret;
    },
    checkModFiles() {
      let files = this.getFiles() || [];
      if (files.length > 0) return true;
      return this.isDiff(this.orgData.files, this.replyData.files);
    },
    resetData(idx) {
      this.pIndex = idx;
      this.replyData =
        this.pIndex == -1 ? {} : this.deepCopy(this.pItems[this.pIndex]);
      this.orgData = this.deepCopy(this.replyData);
      this.vld.replyData.$reset();
      this.clearFileSelect();
    },
    async post() {
      this.loading();
      let params = {
        comment: this.replyData.comment,
      };
      params.loading = true; //ローディング表示を自動で消さない
      let replyId = this.replyData.chat_reply_id;
      if (this.pIndex == -1) {
        params.chatId = this.postData.chat_id;
        let ret = await api.postComment(params);
        if (!ret.response || !ret.response.body) return;
        replyId = ret.response.body.chat_reply_id;
      } else {
        params.chatReplyId = replyId;
        await api.editComment(params);
      }
      //ファイルアップロード
      await this.uploadFiles(
        'chtrp',
        this.postData.chat_id + '|' + replyId,
        replyId,
        (this.replyData.files || []).filter((x) => x.deleted)
      );
      this.gaEvent('chat_reply', {
        category: this.chatCategoryLabel(this.postData.category_id),
        chat_id: this.postData.chat_id,
      });
      this.replyData.files = [];
      this.closeModal();
      this.action();
      this.loaded();
    },
  },
};
</script>
