<template>
  <div class="title">
    <h2 v-if="!isSPMode">{{ title }}一覧</h2>
  </div>

  <div class="status tags">
    <h3>ステータス：</h3>
    <ul>
      <li
        v-for="(item, idx) of ['下書き', '公開予約', '公開中', '公開終了']"
        :key="idx"
      >
        <input
          type="checkbox"
          :id="`sts_${idx}`"
          v-model="checkedStatus"
          v-bind:value="item"
          class="check_tag"
        />
        <label
          :for="`sts_${idx}`"
          :class="checkedStatus.find((x) => x == item) ? 'on' : ''"
          >{{ item }}</label
        >
      </li>
    </ul>
  </div>

  <div class="category_importance_keyword">
    <div class="category tags">
      <h3>カテゴリー：</h3>
      <ul>
        <li v-for="item of infoCategoryItems" :key="item.id">
          <input
            type="checkbox"
            :id="`category_${item.id}`"
            v-model="checkedCategories"
            v-bind:value="item.id"
            class="check_tag"
          />
          <label
            :for="`category_${item.id}`"
            :class="checkedCategories.find((x) => x == item.id) ? 'on' : ''"
            >{{ item.label }}</label
          >
        </li>
      </ul>
    </div>
    <div class="importance">
      <h3>重要度：</h3>
      <ul>
        <li>
          <input
            type="checkbox"
            id="check_importance_1"
            class="check_tag check_importance"
            v-model="importance"
          />
          <label for="check_importance_1" :class="importance ? 'on' : ''"
            >高</label
          >
        </li>
      </ul>
    </div>

    <div class="keyword">
      <input
        v-model.trim="keyword"
        type="text"
        placeholder="キーワードを入力"
        class="type_keyword"
      />
    </div>
  </div>

  <div class="dateSelect">
    <div class="period_date block">
      <h3>掲載開始：</h3>
      <div class="form_group">
        <DatePicker
          id="startDateFrom"
          cls="dateTimePick"
          :value="dateFrom"
          @setValid="setValid"
        />
        <span class="separate">ー</span>
        <DatePicker
          id="startDateTo"
          cls="dateTimePick"
          :value="dateTo"
          @setValid="setValid2"
        />
      </div>
    </div>
    <div class="period_date block">
      <h3>掲載終了：</h3>
      <div class="form_group">
        <DatePicker
          id="endDateFrom"
          cls="dateTimePick"
          :value="dateEndFrom"
          @setValid="setValid3"
        />
        <span class="separate">ー</span>
        <DatePicker
          id="endDateTo"
          cls="dateTimePick"
          :value="dateEndTo"
          @setValid="setValid4"
        />
      </div>
    </div>
    <div class="search">
      <button class="btn" @click="search" :disabled="dateInvalid">検索</button>
    </div>
  </div>
</template>

<script>
import search from '../mixin/search';
import DatePicker from './DatePicker';
import { splt } from '../mixin/constants';
export default {
  name: 'SearchFrameChat',
  mixins: [search],
  components: { DatePicker },

  data() {
    return {
      checkedStatus: [],
      checkedCategories: [],
      keyword: '',
      dateFrom: this.getFromDate() + ' 00:00',
      dateTo: this.fmtymdAdd(30) + ' 23:59',
      dateEndFrom: '',
      dateEndTo: '',
      unreadFlag: false,
      readFlag: false,
      categoryId: 0,
      likeNumber: '',
      replyNumber: '',
      importance: false,
    };
  },
  methods: {
    createParam() {
      let dtlist = ['startDateFrom', 'startDateTo', 'endDateFrom', 'endDateTo'];
      let params = this.getParams(dtlist);
      params.postFlag = '1';
      params.status = this.checkedStatus.join(splt);
      params.category = this.checkedCategories.join(splt);
      if (this.importance) params.importance = '1';
      params.keyword = this.toHalfSpace(this.keyword);
      return params;
    },
  },
};
</script>
