/* eslint-disable no-undef */
var selectFiles = [];
export default function extend() {
  $(window).off();
  $('.pagetop').unbind();
  $('input.fileClip_input').off();
  $('input.fileClip_input_type02').off();
  $('input.fileClip_input_type03').off();
  $(document).off();

  selectFiles = [];
  $('input.fileClip_input').on('change', function () {
    setInputFiles($(this), 'type1');
  });
  //お知らせ投稿画面の添付ファイル挿入箇所(内容は一緒だけど挿入箇所が違う);
  $('input.fileClip_input_type02').on('change', function () {
    setInputFiles($(this), 'type2');
  });
  //画像挿入位置選択後の画像プレビュー(挿入箇所がブロックの外)
  $('input.fileClip_input_type03').on('change', function () {
    setInputFiles($(this), 'type3');
  });
  $(document).on('click', '.file_item_delete', function () {
    $(this).prev().remove();
    $(this).remove();
    $(`.atch_${$(this).data('fn')}`).remove();
    $(`.prv_${$(this).data('fn')}`).remove();
  });
  bindModalButton();
  setDateClear();
  setDatePicker();
}
export function setupDatePicker(id) {
  $('#app').click(function (e) {
    //datePicker以外の場所をクリックされた場合、pickerを閉じる
    if ($(e.target).is(`input#${id}.type_select.datePick.hasDatepicker`))
      return;
    if ($(e.target).is(`input#${id}.type_select.dateTimePick.hasDatepicker`))
      return;
    $(`#${id}`).datepicker('hide');
  });
}
export function setupSPMenu() {
  //sp menu
  $('.navi_toggle').unbind();
  $('.navi_toggle').click(function () {
    $(
      'html, body, .g_header, .g_header .inner, .g_navi, .navi_toggle, .g_navi_overlay'
    ).toggleClass('open');
  });
  $('.g_navi a').off();
  $('.g_navi a').on('click', function () {
    if (window.innerWidth <= 768) {
      $(
        'html, body, .g_header, .g_header .inner, .g_navi, .navi_toggle, .g_navi_overlay'
      ).removeClass('open');
    }
  });

  $('.g_navi_overlay').unbind();
  $('.g_navi_overlay').click(function () {
    $(
      'html, body, .g_header, .g_header .inner, .g_navi, .navi_toggle, .g_navi_overlay'
    ).removeClass('open');
  });
  $('.g_navi .menu_SP + ul').hide();
  $('.g_navi .menu_SP').off();
  $('.g_navi .menu_SP').on('click', function () {
    $(this).next('ul').slideToggle();
    $(this).toggleClass('active');
  });
}
export function clearFileSelect() {
  selectFiles = [];
  $('.file_added').remove();
}
export function removeItem(clsName) {
  $(`.${clsName}`).remove();
}
export function bindModalButton() {
  $('.modalOpen').unbind();
  // $('.modalClose.modalCloseAll').unbind();
  $('.modalOpen').click(function () {
    // var navClass = $(this).attr('class'),
    let href = $(this).attr('data-modal');
    var innerpos = $('.innerModal_body').position();
    $(href).fadeIn().scrollTop(innerpos.top);
    $('.innerModal_body').scrollTop(innerpos.top);
    $(this).addClass('open');
    return false;
  });
  $('.modalClose').not('.confirm').unbind();
  $('.modalClose')
    .not('.confirm')
    .click(async function () {
      $(this).parents('.modal').fadeOut();
      $('.modalOpen').removeClass('open');
      return false;
    });
}
export function setConfirm(cls, func) {
  $(`.modalClose.confirm.${cls}`).unbind();
  $(`.modalClose.confirm.${cls}`).click(async function () {
    if ($(this).closest('.modal').css('display') == 'none') return false;
    if ($(`.ui-dialog`).length > 0) return false;
    let c = await func();
    if (!c) return;
    $(this).parents('.modal').fadeOut();
    $('.modalOpen').removeClass('open');
    return false;
  });
}
export function setDateClear() {
  $('.datePick, .dateTimePick').each(function (i, el) {
    if (el.value != null && el.value != '') {
      if ($(el).closest('.wrapDate').length == 0) {
        // $(el).unwrap();
        $(el).wrap("<div class='wrapDate'></div>");
        let cn = el.classList.contains('dateTimePick')
          ? 'dateTimeClear'
          : 'dateClear';
        $(el).after(`<span class="${cn}"></span>`);
      }
    }
  });
  //お知らせ投稿画面のモーダルのみゴミ箱アイコンで日時を削除
  $('.btn_dateTime_delete').unbind();
  $('.btn_dateTime_delete').click(function () {
    var targetDate = $(this).prev('.wrapDate').children('.dateTimePick');
    if (targetDate.length) $.datepicker._clearDate(targetDate);
  });
}
export function closeNavi() {
  $(`.g_navi_overlay`).click();
}

export function modalShowing() {
  return $('.modalOpen').hasClass('open');
}
export function openModal(id) {
  $(`#${id}`).click();
}
export function closeModal() {
  selectFiles = [];
  $('.file_added').remove();
  $('.modalClose').parents('.modal').fadeOut();
  $('.modalOpen').removeClass('open');
  return false;
}
export function isSPMode() {
  let windowWidth = $(window).width();
  return windowWidth < 768;
}
export function getFiles() {
  return selectFiles;
}

export function loading() {
  $('.loadData.ld').prepend('<div class="loader"><span></span></div>');
}

export function loaded() {
  $('.loader').remove();
}

export function showTabNav(isActiveFirstItem) {
  //トップお知らせタブ
  $('.ul.tabNav li').unbind();

  if (isActiveFirstItem) {
    $('ul.tabNav li:first-child').addClass('active');
  }
  $('.tabcontent .tabBox').hide();
  $('.tabcontent .tabBox:first-child').show();
  $('.tabcontent .tabBox#tab_01').show();
  $('ul.tabNav li').click(function () {
    $('ul.tabNav li').removeClass('active');
    $(this).addClass('active');
    $('.tabBox').hide();
    var activeTab = $(this).find('a').attr('href');
    $(activeTab).fadeIn();
    return false;
  });
}

export function owlCarousel(loop) {
  //トップカルーセル
  $(function () {
    $('.owl-carousel').owlCarousel({
      loop: loop,
      margin: 10,
      items: 3,
      nav: true,
      autoplay: true,
      slideBy: 3,
    });
  });
}

export function showConfirmDialog(message, okFunction, cancelFunction, info) {
  // Dialogを破棄する関数
  var _destroyDialog = function (dialogElement) {
    dialogElement.dialog('destroy'); // ※destroyなので、closeイベントは発生しない
    dialogElement.remove(); // ※動的に生成された要素を削除する必要がある
  };

  var $dialog = $('<div></div>').html(message);

  // ※Dialogを破棄後、コールバック関数を実行する
  var _funcOk = function () {
    $('body').css('overflow', 'auto');
    _destroyDialog($dialog);
    if (okFunction) {
      okFunction();
    }
  };
  var _funcCancel = function () {
    $('body').css('overflow', 'auto');
    _destroyDialog($dialog);
    if (cancelFunction) {
      cancelFunction();
    }
  };
  let buttons = [{ text: info ? '閉じる' : 'はい', click: _funcOk }];
  if (!info) {
    buttons.push({
      text: 'いいえ',
      click: function () {
        $(this).dialog('close');
      },
    });
  }
  $dialog.dialog({
    modal: true,
    title: info ? 'メッセージ' : '確認',

    closeText: 'Cancel',
    closeOnEscape: true,
    close: _funcCancel,

    buttons: buttons,

    open: function () {
      $('body').css('overflow', 'hidden');
    },
  });
}
export function toKB(byte) {
  return (round(Number(byte) / 1024, 1) || 1).toLocaleString();
}
export function round(value, base) {
  base = 10 ** base;
  return Math.round(value * base) / base;
}

const imageArray = new Array(
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif'
);
//ファイル選択時の処理
function setInputFiles(self, type) {
  let files = [];
  let sizeOver = false;
  let notSelectable = false;
  for (let file of self.prop('files')) {
    if ($.inArray(file.type, imageArray) === -1) {
      //画像ファイル以外
      if (type == 'type3') {
        notSelectable = true;
        continue;
      }
    }
    let sames = self
      .closest('.file_input')
      .find('div.file_item')
      .filter(function () {
        return $(this).data('fn') == file.name;
      });
    //同一ファイルは追加しない
    if (sames.length > 0) continue;

    let limitByte = 1024 * 1024;
    if (file.size > limitByte * 10) {
      //サイズ制限
      sizeOver = true;
      continue;
    }

    if (type == 'type3') file.file_div = 1;
    selectFiles.push(file);
    files.push(file);
  }
  let msgs = [];
  if (notSelectable) msgs.push('画像ファイル以外は選択できません');
  if (sizeOver) msgs.push('サイズが10MB以上のファイルは選択できません');
  if (msgs.length > 0) {
    showConfirmDialog(msgs.join('<br><br>'), null, null, true);
  }
  for (let file of files) {
    setFileInput(self, type, file);
  }
  self.val('');
}
//ファイル選択時の処理
function setFileInput(self, type, file) {
  let fileName = file.name;
  let fileSize = file.size;
  let fileType = file.type;
  if ($.inArray(fileType, imageArray) === -1) {
    //画像ファイル以外
    if (type == 'type3') return;
    let fileData = `
				<div class="file_item default file_added" data-fn="${fileName}">
					<a href = "#" >
						${fileName}
						<span>（${toKB(fileSize)}KB</span>
					</a>
				</div>
				<div class="file_item_delete file_added">
					<a href="#" class="asideIcon delete">削除</a>
				</div>`;
    if (type == 'type1') {
      self.parents('.fileClip_wrap').before(fileData);
    } else {
      self.parents('.fileClip_wrap').next('.file_contents').prepend(fileData);
    }
  } else {
    //画像ファイル
    let uID = 'image_' + getUniqueStr();
    let fileNameDel = fileName.replace('.', '$');
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let imageData = `
					<div class="file_item image file_added atch_${fileNameDel}" data-fn="${fileName}"><a href="#"><figure>
						<img src="${
              reader.result
            }" id="${uID}" alt="${fileName}" class="img_responsive">
						<figcaption>${fileName}<span>（${toKB(fileSize)}KB）</span></figcaption>
					</figure></a></div>`;
      let imageDel = `
					<div class="file_item_delete file_added" data-fn="${fileNameDel}">
						<a href="#" class="asideIcon delete">削除</a>
					</div>`;
      if (type == 'type1') {
        $('.fileClip_wrap').before(imageData + imageDel);
      }
      if (type == 'type2') {
        $('.fileClip_wrap')
          .next('.file_contents')
          .prepend(imageData + imageDel);
        $('.img_attach').prepend(imageData);
      }
      if (type == 'type3') {
        $('.fileClip_wrap')
          .parents('.form_group')
          .next('.file_contents')
          .prepend(imageData + imageDel);
        let fig = `<img src="${reader.result}" id="${uID}" alt="${fileName}" class="img_responsive prv_${fileNameDel} file_added">`;
        $('.img_prev').prepend(fig);
      }
    };
  }
}

function getUniqueStr(myStrong) {
  var strong = 1000;
  if (myStrong) strong = myStrong;
  return (
    new Date().getTime().toString(16) +
    Math.floor(strong * Math.random()).toString(16)
  );
}

function setDatePicker() {
  (function (factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
      define(['../widgets/datepicker'], factory);
    } else {
      factory(jQuery.datepicker);
    }
  })(function (datepicker) {
    'use strict';
    datepicker.regional.ja = {
      closeText: '閉じる',
      prevText: '&#x3C;前',
      nextText: '次&#x3E;',
      currentText: '今日',
      monthNames: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
      monthNamesShort: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
      dayNames: [
        '日曜日',
        '月曜日',
        '火曜日',
        '水曜日',
        '木曜日',
        '金曜日',
        '土曜日',
      ],
      dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
      dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
      weekHeader: '週',
      dateFormat: 'yy/mm/dd',
      firstDay: 0,
      isRTL: false,
      showMonthAfterYear: true,
      yearSuffix: '年',
    };
    datepicker.setDefaults(datepicker.regional.ja);
    return datepicker.regional.ja;
  });
}
