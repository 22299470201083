<template>
  <div
    v-for="item of items"
    :key="item.id"
    class="top_chat_item"
    :class="`bg_${categoryIcon(item.category_id)}`"
  >
    <router-link
      :to="`/detail_chat?id=${item.chat_id}`"
      @click="pushGA(item.chat_id)"
    >
      <div class="from">
        <h3>
          <span>{{ item.organization_name }}</span>
          <strong>{{ item.user_name }}</strong>
        </h3>
      </div>
      <div class="category">
        <p>
          <span class="chatIcon" :class="categoryIcon(item.category_id)"></span>
        </p>
      </div>
      <div class="wrap">
        <p class="date">
          投稿：{{ fmtymdS(item.create_datetime)
          }}<br class="hidden_PC hidden_TAB" />(更新：{{
            fmtymdS(item.update_datetime)
          }})
        </p>
        <div class="destination container">
          <span>To</span>
          <strong>{{ item.address }}</strong>
        </div>
        <div class="container">
          <h3 class="title ellipsis2">{{ item.body }}</h3>
        </div>
      </div>
    </router-link>
  </div>
  <nav>
    <p>
      <router-link
        @click.prevent="postNew"
        to="/list_chat"
        @click="pushGA('投稿する')"
        >投稿する</router-link
      >
    </p>
    <p>
      <a :href="chatTermLink" target="_blank" @click="pushGA('利用規約')"
        >利用規約</a
      >
    </p>
  </nav>
</template>

<script>
import dataset from '../mixin/dataset';
import api from '../services/api';
export default {
  name: 'Chat',
  mixins: [dataset],
  created() {
    this.setList();
  },
  data() {
    return { chatTermLink: '' };
  },
  async mounted() {
    let menu = this.$root.menuInfo || {};
    this.chatTermLink = menu.chatterms_link;
  },
  methods: {
    async getData(params) {
      params.pageLimit = 3;
      params.orders = '-update_datetime';
      return await api.getChatList(params);
    },
    async postNew() {
      this.$store.state.tempParams = { modalId: 'newPost' };
    },
    pushGA(title) {
      this.pushGATopPage('チャット', title);
    },
  },
};
</script>
<style scoped>
.container strong {
  white-space: inherit;
}
</style>
