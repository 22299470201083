export default {
  async created() {
    this.routePath = this.$route.path;
    await this.setItems();
    if (this.items.length == 0) return;
    let item = this.items[0];
    switch (this.$route.path) {
      case '/detail_chat':
        this.gaEvent('chat_detail', {
          category: this.chatCategoryLabel(item.category_id),
          chat_id: item.chat_id,
        });
        break;
      case '/detail_news':
        this.gaEvent('notice_detail', {
          category: item.category,
          title: item.title,
          group: item.department_name,
          date: item.publish_start_date,
          importance: item.importance,
        });
        break;
      default:
        break;
    }
  },

  data() {
    return { routePath: '' };
  },
  watch: {
    $route() {
      if (this.routePath != this.$route.path) return;
      if (this.$route.query.id) this.setItems();
    },
  },

  methods: {
    setItems() {},
  },
};
