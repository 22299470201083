<template>
  <main>
    <div class="sidebar">
      <section class="organization" v-if="addressLinks.length > 0">
        <h2>組織図／台帳</h2>
        <ul>
          <li v-for="item of addressLinks" :key="item.id">
            <a
              :href="href(item)"
              @click.prevent="clickLink('組織図／台帳', item)"
            >
              <span v-show="item.icon && item.icon.url" class="icon_img"
                ><img
                  :src="item.icon ? item.icon.url : ''"
                  class="img_responsive icon"
              /></span>
              <span>{{ item.title }}</span>
            </a>
          </li>
        </ul>
      </section>
      <section class="links" v-if="links.length > 0">
        <h2>リンク一覧</h2>
        <ul>
          <li v-for="item of links" :key="item.id">
            <a
              :href="href(item)"
              @click.prevent="clickLink('リンク一覧', item)"
            >
              <span v-show="item.icon && item.icon.url" class="icon_img"
                ><img
                  :src="item.icon ? item.icon.url : ''"
                  class="img_responsive icon"
              /></span>
              <span>{{ item.title }}</span>
            </a>
          </li>
        </ul>
      </section>
      <section class="sales" v-if="salesMenus.length > 0">
        <h2>営業メニュー</h2>
        <ul>
          <li v-for="item of salesMenus" :key="item.id">
            <a
              :href="href(item)"
              @click.prevent="clickLink('営業メニュー', item)"
            >
              <span
                ><img
                  :src="item.icon ? item.icon.url : ''"
                  class="img_responsive sales"
              /></span>
              <strong>{{ item.title }}</strong>
            </a>
          </li>
        </ul>
      </section>
      <section class="tools" v-if="toolList.length > 0">
        <h2>営業業務ツール一覧</h2>
        <ul>
          <li v-for="item of toolList" :key="item.id">
            <a
              :href="href(item)"
              @click.prevent="clickLink('営業業務ツール一覧', item)"
            >
              <span v-show="item.icon && item.icon.url" class="icon_img"
                ><img
                  :src="item.icon ? item.icon.url : ''"
                  class="img_responsive icon"
              /></span>
              <span>{{ item.title }}</span>
            </a>
          </li>
        </ul>
      </section>
    </div>

    <div class="mainContents wrap">
      <div class="primaryContents">
        <section class="top_news">
          <header>
            <h2>お知らせ</h2>
            <p>
              <router-link
                to="/list_news"
                @click="pushGATopPage('お知らせ', '一覧はこちら')"
                >一覧はこちら</router-link
              >
            </p>
          </header>
          <Information ref="info" />
        </section>

        <section class="top_stock">
          <header>
            <h2>株価情報</h2>
            <p>
              <a
                href="https://www.noritz.co.jp/company/ir/chart.html"
                target="_blank"
                >株式チャート</a
              >
            </p>
          </header>
          <div class="loadData">
            <!-- <div class="loader"><span></span></div> -->
            <div class="stockImage">
              <div class="date"></div>
              <div class="price"></div>
            </div>
            <!--/stockImage-->
          </div>
        </section>

        <section class="top_NORNET-TV">
          <div class="col_tv wrap container">
            <h2 class="container">
              <dd>{{ data.nornettv_title }}</dd>
            </h2>
            <p>{{ data.nornettv_detail }}</p>
            <div class="movieArea">
              <p v-html="data.nornettv_code" />
              <button
                v-show="data.nornettv_code"
                class="TVbutton"
                @click="openModal(data.nornettv_code)"
              />
            </div>
            <MovieModal
              v-show="showMovie"
              v-on:from-child="closeModal"
            ></MovieModal>
            <nav>
              <p>
                <a
                  :href="pastMoveUrl"
                  target="_blank"
                  @click="pushGATopPage('社長ノート', '過去動画はこちら')"
                  >過去動画はこちら</a
                >
              </p>
            </nav>
          </div>
          <div class="col_note">
            <h2>社長ノート</h2>
            <div class="loadData">
              <div class="loader bg_transparent"><span></span></div>
              <ul>
                <li v-for="(item, idx) of notes" :key="idx" class="container">
                  <router-link
                    :to="`/detail_news?id=${item.information_id}`"
                    @click="pushGATopPage('社長ノート', item.title)"
                  >
                    <span>{{ fmtymdS(item.publish_start_date) }}</span>
                    <strong>{{ item.title }}</strong>
                  </router-link>
                </li>
              </ul>
            </div>
            <nav>
              <p>
                <a
                  :href="presidentNotePdf"
                  target="_blank"
                  @click="pushGATopPage('社長ノート', 'Mission Value')"
                  >Mission Value</a
                >
              </p>
            </nav>
          </div>
        </section>

        <section class="top_topics">
          <header>
            <h2>トピックス</h2>
            <p>
              <router-link
                to="/topicsList"
                @click="pushGATopPage('トピックス', '一覧はこちら')"
                >一覧はこちら</router-link
              >
            </p>
          </header>
          <Topics ref="tpcs" />
        </section>

        <section class="top_watch">
          <header>
            <h2>ウォッチ</h2>
            <p>
              <router-link
                to="/list_watch"
                @click="pushGATopPage('ウォッチ', 'すべて見る')"
                >すべて見る</router-link
              >
            </p>
          </header>
          <Watch ref="wtch" />
        </section>
      </div>

      <div class="secondaryContents">
        <section class="top_system">
          <SystemInfo ref="sys" />
        </section>

        <section class="top_yulife">
          <header>
            <h2>ゆーらいふねっと</h2>
            <p>
              <a
                :href="yulifeUrl"
                target="_blank"
                @click="
                  gaEvent('top_click', {
                    category: 'ゆーらいふねっと',
                    title: '一覧はこちら',
                  })
                "
                >一覧</a
              >
            </p>
          </header>
          <ul>
            <li v-for="(item, idx) of yulifeList" :key="idx" class="container">
              <a
                :href="item.url"
                target="_blank"
                @click="
                  gaEvent('top_click', {
                    category: 'ゆーらいふねっと',
                    title: item.title,
                  })
                "
              >
                <span>{{ item.date }}</span>
                <strong>{{ item.title }}</strong>
              </a>
            </li>
          </ul>
        </section>

        <section class="top_co2">
          <header>
            <h2><strong>CO2</strong>削減目標達成率</h2>
            <p>
              <a
                href="#"
                class="modalOpen"
                data-modal="#modal-top_co2"
                title="CO2削減目標達成率とは"
                >CO2削減目標達成率とは?</a
              >
            </p>
          </header>
          <div class="loadData">
            <div class="loader bg_transparent"><span></span></div>
            <div class="top_co2_data">
              <!--★下記.top_co2_data_itemのlevel_1～level5のclass付け替えで背景とはぴワクくん画像が変わります-->
              <div class="top_co2_data_item" :class="`level_${co2BuzVal.lv}`">
                <p>
                  <span>{{ co2BuzVal.val1 }}</span
                  >.{{ co2BuzVal.val2 }}%
                </p>
              </div>
              <div class="top_co2_data_item" :class="`level_${co2PrdVal.lv}`">
                <p>
                  <span>{{ co2PrdVal.val1 }}</span
                  >.{{ co2PrdVal.val2 }}%
                </p>
              </div>
            </div>
          </div>
        </section>

        <div class="modal" id="modal-top_co2">
          <div class="overLay modalClose"></div>
          <div class="innerModal co2">
            <span class="btn modalClose outer"></span>
            <div class="innerModal_header">
              <h2><strong>CO2</strong>削減目標達成率とは？</h2>
            </div>
            <div class="innerModal_body heightFull">
              <p class="m-b-20">
                組織全体のCO2削減目標に対する達成率を表示しています。
              </p>
              <div class="co2_group">
                <div class="co2_data_txt sp_m-b-5">
                  <p>
                    <img
                      src="@/assets/img/top/tit_co2_jigoy_left.svg"
                      alt="事業"
                      class="m-r-5"
                    />は国内事業所による<br
                      class="hidden_SP_TAB"
                    />CO2排出量の削減目標達成率を表示しています。<br
                      class="hidden_SP_TAB"
                    /><span class="hidden_SP_TAB"
                      >右図の５段階で評価されます。</span
                    >
                  </p>
                  <p>
                    <img
                      src="@/assets/img/top/tit_co2_seihin_left.svg"
                      alt="事業"
                      class="m-r-5"
                    />は国内で製造・販売する製品使用時の<br
                      class="hidden_SP_TAB"
                    />CO2排出量の削減目標達成率を表示しています。<br
                      class="hidden_SP_TAB"
                    /><span class="hidden_SP_TAB"
                      >右図の５段階で評価されます。</span
                    >
                  </p>
                </div>
                <h3 class="hidden_PC"></h3>
                <div class="co2_data_list">
                  <div
                    v-for="(item, idx) of co2Vals"
                    :key="item.lv"
                    class="top_co2_data_item cols"
                    :class="`level_${5 - idx}`"
                  >
                    <p>
                      <span>{{ item.frm1 }}</span
                      >{{ item.frm2 }}～<span>{{ item.to1 }}</span
                      >{{ item.to2 }}
                    </p>
                  </div>
                </div>
              </div>

              <p class="note">
                削減目標達成率の変化によって、はぴワクくんが変化します。<br
                  class="hidden_PC"
                />目標達成すると<img
                  src="@/assets/img/top/i_co2_image.svg"
                  alt="事業"
                />が出現します。削減目標達成を目指して、がんばりましょう！
              </p>
              <br />
              <p>
                CO2削減目標達成率は、2018年度比のゴール目標に向けた{{
                  co2rate('year')
                }}年の目標に対する達成率です。
              </p>
              <ul>
                <li>
                  <span href="#" class="modalClose btn type_cancel"
                    >閉じる</span
                  >
                </li>
                <li>
                  <a
                    :href="co2rate('link')"
                    target="_blank"
                    class="btn type_co2"
                    >さらに詳しい情報
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section class="top_chat">
          <header>
            <h2>チャット</h2>
            <p>
              <router-link
                to="/list_chat"
                @click="pushGATopPage('チャット', '一覧はこちら')"
                >一覧はこちら</router-link
              >
            </p>
          </header>
          <Chat ref="chat" />
        </section>

        <section class="top_ranking">
          <header>
            <h2>お知らせランキング</h2>
            <p>
              <router-link
                to="/list_ranking"
                @click="pushGATopPage('お知らせランキング', 'すべて見る')"
                >すべて見る</router-link
              >
            </p>
          </header>
          <Ranking />
        </section>

        <nav class="top_aside">
          <ul>
            <li
              v-for="(item, idx) of [link1, link2, link3, link4].filter(
                (x) => x.id
              )"
              :key="item.id"
            >
              <a
                :href="href(item)"
                @click.prevent="clickLink('リンク' + idx, item)"
                class="regulations"
              >
                <span
                  ><img
                    :src="item.icon ? item.icon.url : ''"
                    class="img_responsive icon"
                /></span>
                <strong>{{ item.title }}</strong>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div class="footContents">
        <section class="top_group" v-if="departmentPages.length > 0">
          <h2>部門ホームページ</h2>
          <ul>
            <li v-for="item of departmentPages" :key="item.id">
              <a
                :href="href(item)"
                @click.prevent="clickLink('部門ホームページ', item)"
              >
                <span v-show="item.icon && item.icon.url" class="icon_img"
                  ><img
                    :src="item.icon ? item.icon.url : ''"
                    class="img_responsive icon"
                /></span>
                <span>{{ item.title }}</span>
              </a>
            </li>
          </ul>
        </section>

        <section class="top_infoSite" v-if="infoSites.length > 0">
          <h2>各種情報サイト</h2>
          <ul>
            <li v-for="item of infoSites" :key="item.id">
              <a
                :href="href(item)"
                @click.prevent="clickLink('各種情報サイト', item)"
              >
                <span
                  ><img
                    :src="item.icon ? item.icon.url : ''"
                    class="img_responsive icon2"
                /></span>
                <strong>{{ item.title }}</strong>
              </a>
            </li>
          </ul>
        </section>

        <nav class="top_hapiwaku">
          <p>
            <a
              :href="hapiwakuUrl"
              target="_blank"
              @click="
                pushGATopPage(
                  'はぴワクくん',
                  'ITサポートははぴワクくんが何でもお答えします'
                )
              "
            >
              <img
                src="@/assets/img/top/i_top_hapiwakukun.svg"
                alt="ITサポートははぴワクくんが何でもお答えします"
                class="img_responsive"
              />
            </a>
          </p>
        </nav>
      </div>
    </div>
  </main>
</template>

<script>
import Information from '../components/Information.vue';
import Topics from '../components/Topics.vue';
import Chat from '../components/Chat.vue';
import SystemInfo from '../components/SystemInfo.vue';
import Watch from '../components/Watch.vue';
import Ranking from '../components/Ranking.vue';
import MovieModal from '../components/MovieModal.vue';
import view from '../mixin/view';
import dataset from '../mixin/dataset';
import api from '../services/api';
export default {
  name: 'Top',
  components: {
    Topics,
    Chat,
    Information,
    SystemInfo,
    Watch,
    Ranking,
    MovieModal,
  },
  data() {
    return {
      notes: [],
      yulifeList: [],
      showMovie: false,
      refreshTimer: null,
      co2BuzVal: {},
      co2PrdVal: {},
      co2Vals: [],
    };
  },
  mixins: [view, dataset],
  async created() {
    this.notes = await this.getNoteData();
    this.yulifeList = await this.getYulifeList();
    this.$store.commit('setIdList', []);

    //co2削減目標
    let getValus = () => {
      return [...Array(5)].reduce((acc, _, idx) => {
        let frmNum = idx == 0 ? 0 : Number(this.co2rate(null, idx));
        let toNum = idx == 4 ? 0 : Number(this.co2rate(null, idx + 1)) - 0.1;

        let frm = frmNum.toFixed(1).split('.');
        let to = toNum.toFixed(1).split('.');

        let item = {
          frm: frmNum,
          frm1: frm[0],
          frm2: frm[1] == '0' ? '' : '.' + frm[1],
          to1: to[0] == '0' ? '' : to[0],
          to2: to[1] == '0' ? '' : '.' + to[1],
        };
        if (idx == 4) {
          item.frm2 += '%';
        } else {
          item.to2 += '%';
        }
        acc.push(item);
        return acc;
      }, []);
    };
    this.co2Vals = getValus();

    let getVal = (div) => {
      let val = this.co2rate(div);
      let lv = this.co2Vals.reduce((acc, item, idx) => {
        if (val >= item.frm) acc = 5 - idx;
        return acc;
      }, 1);
      let valA = Number(val).toFixed(1).split('.');
      return { lv: lv, val1: valA[0], val2: valA[1] };
    };
    this.co2BuzVal = getVal('business');
    this.co2PrdVal = getVal('product');
  },
  async beforeRouteLeave() {
    clearInterval(this.refreshTimer);
  },
  mounted() {
    //表示リフレッシュ
    let refs = ['info', 'tpcs', 'wtch', 'chat', 'sys'];
    this.refreshTimer = setInterval(() => {
      for (let ref of refs) {
        if (!this.$refs[ref]) continue;
        this.$refs[ref].setList();
      }
    }, 1000 * 60 * 60);
  },
  methods: {
    onMessageReceived() {
      // var data = e.data;
      this.gaEvent('movie_view', {
        title: this.data.nornettv_detail,
      });
    },
    async getNoteData() {
      let params = {};
      params.pageLimit = 3;
      params.pageNumber = 1;
      params.category = this.infoCateNote;
      let ret = await api.getInformationList(params);
      return ret.contents && ret.contents.length > 0 ? ret.contents : [];
    },
    async getYulifeList() {
      let ret = api.getYulifeList({});
      return ret || [];
    },
    openModal(code) {
      if (this.showMovie) return;
      window.addEventListener('message', this.onMessageReceived, false);
      const div = document.createElement('div');
      div.id = 'movie';
      div.innerHTML = code;
      this.showMovie = true;
      let show = () => {
        let ele = document.getElementById('iframeModal');
        ele.innerHTML = '';
        ele.appendChild(div);
      };
      setTimeout(show, 1);
    },
    closeModal() {
      var el = document.getElementById('movie');
      el.remove();
      this.showMovie = false;
    },
    co2rate(div, no) {
      let lbl = no ? `lv${no}` : `${div}`;
      return this.data[`co2rate_${lbl}`];
    },
  },
  computed: {
    data() {
      return this.$root.menuInfo || {};
    },
    link1() {
      return this.data.link1 || {};
    },
    link2() {
      return this.data.link2 || {};
    },
    link3() {
      return this.data.link3 || {};
    },
    link4() {
      return this.data.link4 || {};
    },
    addressLinks() {
      return this.data.address_link || [];
    },
    links() {
      return this.data.link_list || [];
    },
    salesMenus() {
      return this.data.sales_menu || [];
    },
    toolList() {
      return this.data.sales_tool_list || [];
    },
    departmentPages() {
      return this.data.department_hp || [];
    },
    infoSites() {
      return this.data.information_site || [];
    },
    pastMoveUrl() {
      return this.data.nornettv_link;
    },
    presidentNotePdf() {
      return this.data.missionvalue_link;
    },
  },
};
</script>
<style>
/* NORNET-TV サムネイルリンクにサイズを合わせて透明化*/
.TVbutton {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
/* NORNET-TV サムネイル用にするため、リンクを押せないように最背面に設定 */
.iframe {
  z-index: -1;
}
img.img_responsive.icon {
  max-width: 25px;
  max-height: 25px;
}
img.img_responsive.icon2 {
  max-height: 45px;
}
img.img_responsive.sales {
  max-height: 25px;
}
.icon_img {
  width: 25px;
  margin: 5px;
}
.footContents .top_infoSite ul li a span {
  height: auto;
}
.modal .innerModal.co2 {
  max-width: 1120px;
}
.top_co2_data_item.cols p span {
  font-size: 2.6rem;
}
</style>
