import store from '../store/index';

export default {
  async check() {
    let hashValue = store.state.hashValue;
    if (hashValue) {
      const timeStamp = this.getimeStamp();
      // 有効期限内
      let exp = store.state.expires;
      if (exp && !isNaN(exp) && exp >= timeStamp) return null;
      // 有効期限切れ(->認証)
      store.commit('setHashValue', null);
    } else {
      const url = new URL(window.location);
      if (url.pathname == '/undefined') {
        // 不正なパス(->エラーページ)
        return '/error.html';
      }
      if (url.hash && url.hash != '') {
        // 認証済み（->ログイン中表示->トップまたはリダイレクト先）
        const hash = this.getHash(url.hash);
        store.commit('setHashValue', hash);
        let exp = this.getimeStamp() + Number(hash.expires_in);
        store.commit('setExpires', exp);
      } else {
        // 初回アクセス
        store.commit('setTempParams', url);
      }
    }
    return '/login';
  },

  getHash(hash) {
    const result = {};
    const hashs = hash.toString().replace('#', '').split('&');
    for (const hash of hashs) {
      const parse = hash.split('=');
      result[parse[0]] = parse[1];
    }
    return result;
  },

  getimeStamp() {
    return Math.floor(new Date().getTime() / 1000);
  },
};
