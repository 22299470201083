<template>
  <div>
    <header class="g_header">
      <div class="scrolled_wrap">
        <h1 class="logo">
          <router-link
            to="/"
            title="NORNET - ノーリツグループ従業員一人ひとりの明日を考える"
            >NORNET -
            ノーリツグループ従業員一人ひとりの明日を考える</router-link
          >
        </h1>

        <div class="inner">
          <div class="user_data">
            <nav>
              <ul>
                <li>
                  <a
                    href="/setting_keywordWatch"
                    data-tooltip="キーワードウォッチ"
                  >
                    <img
                      src="@/assets/img/common/header/icon_watchList.svg"
                      alt="キーワードウォッチ"
                      class="img_responsive"
                    />
                  </a>
                </li>
              </ul>
            </nav>
            <div class="userBtn">
              <h2
                class="userInfo"
                :class="showUserInfo ? 'open' : ''"
                @click="
                  (_) => {
                    showUserInfo = !showUserInfo;
                  }
                "
              >
                <span>{{ userInfo.orgName }}</span>
                <strong>{{ userInfo.name }}</strong>
              </h2>
              <ul>
                <li>
                  <a href="#" @click.prevent.stop="logout">
                    <span>
                      <img
                        src="@/assets/img/common/header/icon_logout.svg"
                        alt="ログアウト"
                        class="img_responsive"
                      />
                    </span>
                    <strong>ログアウト</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div v-if="globalMsg" class="header_alert">
            <h3>
              <a
                :href="globalMsg.url"
                target="_blank"
                @click.prevent.stop="moveGlbLink(globalMsg)"
              >
                <span
                  ><img
                    :src="globalMsg.icon ? globalMsg.icon.url : ''"
                    class="img_responsive glbmsg"
                /></span>
                <strong>{{ globalMsg.title }}</strong>
              </a>
            </h3>
          </div>
          <nav class="g_navi">
            <ul>
              <li>
                <router-link to="/" v-if="showContents">
                  <span>
                    <img
                      src="@/assets/img/common/g_navi/icon_home.svg"
                      alt="ホーム"
                      class="img_responsive link"
                    />
                  </span>
                  <strong>ホーム</strong>
                </router-link>
              </li>
              <li v-for="item of globalLinks" :key="item.id">
                <a :href="href(item)" @click.prevent.stop="moveGlbLink(item)">
                  <span>
                    <img
                      :src="item.icon.url"
                      class="img_responsive link"
                      :alt="item.title"
                  /></span>
                  <strong>{{ item.title }}</strong>
                </a>
              </li>
              <!--★以下spのみ表示メニュー-->
              <li class="hidden_PC hidden_TAB" v-if="addressLinks.length > 0">
                <div class="menu_SP">
                  <span
                    ><img
                      src="@/assets/img/top/icon_organization_red.svg"
                      alt="組織図／台帳"
                      class="img_responsive icon"
                  /></span>
                  <strong>組織図／台帳</strong>
                </div>
                <ul>
                  <li v-for="item of addressLinks" :key="item.id">
                    <a
                      :href="href(item)"
                      @click.prevent.stop="clickLink('組織図／台帳', item)"
                    >
                      <span v-show="item.icon && item.icon.url"
                        ><img
                          :src="item.icon ? item.icon.url : ''"
                          class="img_responsive icon"
                      /></span>
                      {{ item.title }}
                    </a>
                  </li>
                </ul>
              </li>
              <li class="hidden_PC hidden_TAB" v-if="links.length > 0">
                <div class="menu_SP">
                  <span
                    ><img
                      src="@/assets/img/top/icon_links_red.svg"
                      alt="リンク一覧"
                      class="img_responsive icon"
                  /></span>
                  <strong>リンク一覧</strong>
                </div>
                <ul>
                  <li v-for="item of links" :key="item.id">
                    <a
                      :href="href(item)"
                      @click.prevent.stop="clickLink('リンク一覧', item)"
                    >
                      <span v-show="item.icon && item.icon.url"
                        ><img
                          :src="item.icon ? item.icon.url : ''"
                          class="img_responsive icon"
                      /></span>
                      {{ item.title }}
                    </a>
                  </li>
                </ul>
              </li>
              <li class="hidden_PC hidden_TAB" v-if="salesMenus.length > 0">
                <div class="menu_SP">
                  <span
                    ><img
                      src="@/assets/img/top/icon_sales_red.svg"
                      alt="営業メニュー"
                      class="img_responsive icon"
                  /></span>
                  <strong>営業メニュー</strong>
                </div>
                <ul class="sales">
                  <li v-for="item of salesMenus" :key="item.id">
                    <a
                      :href="href(item)"
                      @click.prevent.stop="clickLink('営業メニュー', item)"
                    >
                      <span
                        ><img
                          :src="item.icon ? item.icon.url : ''"
                          class="img_responsive icon"
                      /></span>
                      <strong>{{ item.title }}</strong>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="hidden_PC hidden_TAB" v-if="toolList.length > 0">
                <div class="menu_SP">
                  <span
                    ><img
                      src="@/assets/img/top/icon_tools_red.svg"
                      alt="営業業務ツール一覧"
                      class="img_responsive icon"
                  /></span>
                  <strong>営業業務ツール一覧</strong>
                </div>
                <ul>
                  <li v-for="item of toolList" :key="item.id">
                    <a
                      :href="href(item)"
                      @click.prevent.stop="
                        clickLink('営業業務ツール一覧', item)
                      "
                    >
                      <span v-show="item.icon && item.icon.url"
                        ><img
                          :src="item.icon ? item.icon.url : ''"
                          class="img_responsive icon"
                      /></span>
                      {{ item.title }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div class="navi_toggle">
        <div>
          <span></span>
          <span></span>
        </div>
      </div>

      <div class="g_navi_overlay"></div>
    </header>
    <div class="header_alert_sp">
      <h3 v-if="globalMsg">
        <a
          :href="globalMsg.url"
          target="_blank"
          @click.prevent.stop="moveGlbLink(globalMsg)"
        >
          <span
            ><img
              :src="globalMsg.icon ? globalMsg.icon.url : ''"
              class="img_responsive glbmsg"
          /></span>
          <strong>{{ globalMsg.title }}</strong>
        </a>
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderFrame',
  data() {
    return {
      showContents: false,
      showUserInfo: false,
    };
  },
  props: {
    userInfo: { default: () => {} },
    menu: { default: () => {} },
  },
  methods: {
    async logout() {
      this.$store.commit('setHashValue', null);
      location.href = process.env.VUE_APP_LOGOUT_URL;
    },
    moveGlbLink(item) {
      this.gaEvent('global_menu', { title: item.title });
      this.movePage(item);
    },
  },
  watch: {
    menu: {
      handler: function () {
        this.showContents = true;
        setTimeout(() => {
          this.setupSPMenu();
        }, 1000);
      },
      deep: true,
    },
  },
  mounted() {
    setTimeout(() => {
      //CMSエラーの場合もホームアイコンを表示するため
      this.showContents = true;
    }, 3000);
  },
  computed: {
    globalMsg() {
      return this.menu.global_message;
    },
    globalLinks() {
      return this.menu.global_link || [];
    },
    addressLinks() {
      return this.menu.address_link || [];
    },
    links() {
      return this.menu.link_list || [];
    },
    salesMenus() {
      return this.menu.sales_menu || [];
    },
    toolList() {
      return this.menu.sales_tool_list || [];
    },
  },
};
</script>
<style lang="scss" scoped>
img.img_responsive.link {
  max-height: 35px;
}
img.img_responsive.glbmsg {
  max-height: 25px;
}
img.img_responsive.icon {
  max-height: 20px;
}
div.user_data {
  width: 350px;
}
h1.logo {
  z-index: 1;
}
</style>
