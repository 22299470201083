import utils from '../mixin/utils';
import { loaded } from '../assets/js/extend';
import store from '../store/index';

let apiurl = process.env.VUE_APP_API_URL;

export default {
  convQuery(pQuery) {
    if (!pQuery) return '';
    let query = '?';
    //クエリ文字列の順番でCORSの制約に引っかかるみたいなので考慮する
    let list = Object.entries(pQuery).sort();
    for (const [key, value] of list) {
      if (utils.methods.isEmpty(value)) continue;
      query +=
        (query == '?' ? '' : '&') + `${key}=${encodeURIComponent(value)}`;
    }
    return query;
  },
  async getData(action, params) {
    if (!params) params = {};
    let ret = await this.request('get', action, params);
    if (!ret.response) return {};

    let status = ret.response.statusCode;
    if (status) {
      if (status == 204) window.location = '/not_found';
      if (status != 200) return { statusCode: status };
      ret.response.body.statusCode = status;
    }
    return ret.response.body;
  },
  async post(action, params) {
    return await this.request('post', action, params);
  },
  async delete(action, params) {
    return await this.request('delete', action, params);
  },
  async patch(action, params) {
    return await this.request('patch', action, params);
  },
  async request(method, action, params) {
    let hashValue = store.state.hashValue;
    if (!params.id_token && !hashValue) return {};
    let httpErr = () => {
      utils.methods.showPopError(
        '通信に失敗しました。通信環境を確認して再度操作をおこなってください。'
      );
    };

    let url = `${apiurl}/${action}`;
    if (method == 'get' && params) {
      url += this.convQuery(params);
    }

    let headers = {
      TokenAuthorization: params.id_token || hashValue.id_token,
      'Content-Type': 'application/json',
    };

    //ローディング表示を消さないフラグ
    let loading = params.loading;
    delete params.loading;

    //APIログ
    utils.methods.outlog(`${method} ${url}`);

    let ret = await this.fetch(headers, method, url, params);
    if (!loading) loaded();
    if (ret) {
      if (!ret.response || ret.response.statusCode >= 400) {
        let msg = `api error!! ${method} ${url} `;
        if (utils.data().isDev && ret.message) {
          msg += `${ret.message}`;
        }
        if (utils.data().isDev && ret.response && ret.response.body) {
          msg += `${ret.response.body}`;
        }
        utils.methods.outlog(msg);
        httpErr();
      }
      return ret;
    } else {
      httpErr();
      return { responseCode: 404 };
    }
  },
  async fetch(headers, method, url, params) {
    return await new Promise((resolve) => {
      let conf = {
        headers: headers,
        method: method.toUpperCase(),
        mode: 'cors',
      };
      if (method != 'get') conf.body = JSON.stringify(params);
      fetch(url, conf)
        .then(function (res) {
          resolve(res.json());
        })
        .catch(function (error) {
          resolve(error);
        });
    });
  },
};
