<template>
  <section class="list_result ranking">
    <header>
      <div class="title">
        <h2>お知らせランキング</h2>
      </div>
    </header>
    <div class="loadData">
      <div class="aggregation_period">
        <h2>集計期間：</h2>
        <p>
          <strong>{{ fmtymdAdd(-7) }}</strong
          ><span>-</span><strong>{{ fmtymd() }}</strong>
        </p>
      </div>
      <div class="result_contents">
        <div class="loader"><span></span></div>
        <div
          class="result_contents_item"
          :class="`${item.read_flag ? 'unread' : ''}`"
          v-for="item of items"
          :key="item.information_id"
        >
          <router-link :to="`/detail_news?id=${item.information_id}`">
            <div class="rank" :class="rankclass(item)">
              <h3>{{ item.rank }}</h3>
            </div>
            <div class="category">
              <p>
                <span class="news_category">{{ item.category }}</span>
              </p>
            </div>
            <div class="importance">
              <p>
                <span
                  class="asideIcon important"
                  :class="importanceFlag(item.importance_id) ? 'high' : ''"
                ></span>
              </p>
            </div>
            <div class="date">
              <p>{{ fmtymdS(item.publish_start_date) }}</p>
            </div>
            <div class="title">
              <h3>
                <h3>{{ item.title }}</h3>
              </h3>
            </div>
            <div class="group">
              <p>{{ item.department_name }}</p>
            </div>
            <div class="good">
              <p class="asideIcon good type_gray">{{ item.like_count }}</p>
            </div>
            <div class="watch">
              <p>
                <span
                  class="asideIcon"
                  :class="item.watch_flag ? 'watch' : 'unwatch'"
                ></span>
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import view from '../mixin/view';
import dataset from '../mixin/dataset';
import api from '../services/api';

export default {
  name: 'RankingList',
  components: {},
  mixins: [view, dataset],
  created() {
    this.setList();
  },
  computed: {
    rankclass: function () {
      return function (item) {
        return item.rank >= '1' && item.rank <= '5' ? `rank_0${item.rank}` : '';
      };
    },
  },
  methods: {
    async getData(params) {
      return await api.getInformationRanking(params);
    },
  },
};
</script>
