<template>
  <section class="loadData" :class="`list_result detail_${kind}`">
    <div class="loader"><span></span></div>
    <header>
      <div class="title">
        <p class="back">
          <router-link :to="`/${backPage}`">
            {{ backPageLabel }}へ戻る
          </router-link>
        </p>
      </div>
    </header>
    <slot></slot>
    <footer>
      <p class="back">
        <router-link :to="`/${backPage}`">
          {{ backPageLabel }}へ戻る
        </router-link>
      </p>
      <nav class="paging">
        <ul v-show="showPaging">
          <li class="prev" :class="prevId == '' ? 'disabled' : ''">
            <a
              :href="`/detail_chat?id=${prevId}`"
              @click.prevent="fetch(prevId)"
              >前のスレッド</a
            >
          </li>

          <li class="next" :class="nextId == '' ? 'disabled' : ''">
            <a
              :href="`/detail_chat?id=${nextId}`"
              @click.prevent="fetch(nextId)"
              >次のスレッド</a
            >
          </li>
        </ul>
      </nav>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'ThredFrame',
  props: {
    kind: { default: '' },
    pageName: { default: '' },
    action: {
      type: Function,
      require: true,
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.idList = this.$store.state.idList;
  },
  data() {
    return {
      id: '',
      idList: [],
    };
  },
  computed: {
    idx() {
      let id = this.kind == 'chat' ? Number(this.id) : this.id;
      return this.idList.indexOf(id);
    },
    prevId() {
      if (this.idx - 1 < 0) return '';
      return this.idList[this.idx - 1];
    },
    nextId() {
      if (this.idx + 1 > this.idList.length - 1) return '';
      return this.idList[this.idx + 1];
    },
    backPageLabel() {
      return this.pageName == '' ? 'ホーム' : `${this.pageName}一覧`;
    },
    backPage() {
      switch (this.kind) {
        case 'chat':
          return 'list_chat';
        // break;
        case 'news':
          return 'list_news';
        default:
          return '';
      }
    },
    showPaging() {
      return this.idList.length > 0 && this.backPage != '';
    },
  },

  methods: {
    fetch(id) {
      if (id == '') return;
      this.id = id;
      let page = this.kind == 'chat' ? `detail_chat` : `detail_news`;
      this.$router.push(`/${page}?id=${id}`);
      //ページ先頭へ移動
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    },
  },
};
</script>
