// import utils from '../mixin/utils';
// import common from '../assets/js/common';
import { splt } from '../mixin/constants';
let pageLimit = Number(process.env.VUE_APP_PAGE_LIMIT);
export default {
  // mounted() {},
  data() {
    return {
      items: [],
      totalCount: 0,
      currentParams: {},
      noAuth: false,
    };
  },

  methods: {
    async setList(params, reset, recoveryParams) {
      //ソート条件のマージ
      if (params && params.orders) {
        let orders = this.currentParams.orders;
        if (orders) {
          let orderP = params.orders.replace('-', '');
          let arr = orders.split(splt);
          arr = arr.filter((x) => x.indexOf(orderP) < 0);
          if (arr.length > 0) params.orders += splt + arr.join(splt);
        }
      }
      if (reset) {
        params.pageNumber = 1;
        params.pageLimit = pageLimit;
        if (this.currentParams.pageLimit) {
          params.pageLimit = this.currentParams.pageLimit;
        }
        if (recoveryParams) {
          params.pageNumber = recoveryParams.pageNumber;
          params.pageLimit = recoveryParams.pageLimit;
          params.orders = recoveryParams.orders;
        }
        this.currentParams = params;
      } else {
        Object.assign(this.currentParams, params);
      }
      //ページングの状態を設定する
      if (this.$refs.list) {
        this.$refs.list.pageNo = this.currentParams.pageNumber;
        this.$refs.list.pageLimit = this.currentParams.pageLimit;
        this.$refs.list.setSortState(this.currentParams.orders);
      }
      let ret = await this.getData(this.currentParams);
      this.items = ret.contents || [];
      this.totalCount = ret.totalCount || 0;
      this.noAuth = ret.statusCode == 210;
      //utils.methods.outlog(this.items);
    },
    async setIdList(id) {
      let p = Object.assign({}, this.currentParams);
      delete p.pageLimit; //IDリスト取得設定
      delete p.pageNumber; //IDリスト取得設定
      let ret = await this.getData(p);
      let list = ret.contents || [];
      this.$store.commit(
        'setIdList',
        list.map((x) => x[id])
      );
      this.$store.dispatch('setSaveParams', {
        path: this.$route.path,
        params: this.currentParams,
      });
    },
    async getData() {
      return [];
    },
    conditionSearch() {
      if (this.$refs.search) {
        let search = this.$refs.search;
        search.search();
      }
    },
  },
};
